import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PractitionerSidebar = ({ selection, handleSide1, handleSide2, handleSide3, handleSide4, handleSide5, handleSide6 }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathToSelectionMap = {
    '/dashboard': 0,
    '/schedule': 1,
    '/appointments': 2,
    '/services': 3,
    '/inbox': 4,
    '/profile': 5,
  };


  useEffect(() => {
    const newSelection = pathToSelectionMap[location.pathname] || 0;
    if (newSelection !== selection) {
      switch (newSelection) {
        case 0:
          handleSide1();
          break;
        case 1:
          handleSide2();
          break;
        case 2:
          handleSide3();
          break;
        case 3:
          handleSide4();
          break;
        case 4:
          handleSide5();
          break;
        case 5:
          handleSide6();
          break;
        default:
          break;
      }
    }
  }, [location.pathname]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (


    <div
      className="min-h-[800px] max-h-[1000px] min-w-[300px] max-w-[300px] box-border flex flex-col items-start justify-start py-4 px-[15px] border-silver-200 mq1050:hidden mq450:gap-[134px]"
      style={{ backgroundColor: '#F9FBFC' }}
    >
      <div className="self-stretch flex flex-col items-center justify-center gap-[8px]">
        <div
          className={`self-stretch rounded-lg ${selection === 0 ? 'bg-[#EFF0F1] w-[90%]' : 'bg-[#F9FBFC]'} flex flex-row items-center justify-center py-4 px-3 gap-[12px] text-neutral-10`}
        >
          <img className="h-6 w-6 relative min-h-[24px]" alt="" src="/sidebar-icons.svg" />
          <div
            className={`flex-1 relative leading-[24px] cursor-pointer mq450:text-base mq450:leading-[19px] ${selection === 0 ? 'font-bold' : ''
              }`}

            onClick={() => {
              handleNavigation('/dashboard');
              handleSide1();
            }}

          >
            Dashboard
          </div>
        </div>
        <div
          className={`self-stretch rounded-lg  ${selection === 1 ? 'bg-[#EFF0F1] w-[90%]' : 'bg-[#F9FBFC]'}  flex flex-row items-center justify-center py-4 px-3 gap-[12px] text-neutral-10`}
        >
          <img className="h-6 w-6 relative min-h-[24px]" alt="" src="/sidebar-icons-1.svg" />
          <div
            className={`flex-1 relative leading-[24px] cursor-pointer mq450:text-base mq450:leading-[19px] ${selection === 1 ? 'font-bold' : ''
              }`}

            onClick={() => {
              handleNavigation('/schedule');
              handleSide2();
            }}

          >
            Schedule
          </div>
        </div>
        <div
          className={`self-stretch rounded-lg  ${selection === 2 ? 'bg-[#EFF0F1] w-[90%]' : 'bg-[#F9FBFC]'} flex flex-row items-center justify-center py-4 px-3 gap-[12px] text-neutral-10`}
        >
          <img className="h-6 w-6 relative min-h-[24px]" alt="" src="/sidebar-icons-2.svg" />
          <div
            className={`flex-1 relative leading-[24px] cursor-pointer mq450:text-base mq450:leading-[19px] ${selection === 2 ? 'font-bold' : ''
              }`}


            onClick={() => {
              handleNavigation('/appointments');
              handleSide3();
            }}

          >
            Appointments
          </div>
        </div>
        <div
          className={`self-stretch rounded-lg  ${selection === 3 ? 'bg-[#EFF0F1] w-[90%]' : 'bg-[#F9FBFC]'} flex flex-row items-center justify-center py-4 px-3 gap-[12px] text-neutral-10`}
        >
          <img className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]" alt="" src="/sidebar-icons-3.svg" />
          <div
            className={`flex-1 relative leading-[24px] cursor-pointer mq450:text-base mq450:leading-[19px] ${selection === 3 ? 'font-bold' : ''
              }`}


            onClick={() => {
              handleNavigation('/services');
              handleSide4();
            }}

          >
            Services
          </div>
        </div>
        <div
          className={`self-stretch rounded-lg  ${selection === 4 ? 'bg-[#EFF0F1] w-[90%]' : 'bg-[#F9FBFC]'} flex flex-row items-center justify-center py-4 px-3 gap-[12px] text-neutral-10`}
        >
          <img className="h-6 w-6 relative min-h-[24px]" alt="" src="/sidebar-icons-4.svg" />
          <div
            className={`flex-1 relative leading-[24px] cursor-pointer mq450:text-base mq450:leading-[19px] ${selection === 4 ? 'font-bold' : ''
              }`}

            onClick={() => {
              handleNavigation('/inbox');
              handleSide5();
            }}

          >
            Inbox
          </div>
        </div>
        <div
          className={`self-stretch rounded-lg  ${selection === 5 ? 'bg-[#EFF0F1] w-[90%]' : 'bg-[#F9FBFC]'}  flex flex-row items-center justify-center py-4 px-3 gap-[12px] text-neutral-10`}
        >
          <img className="h-6 w-6 relative min-h-[24px]" alt="" src="/sidebar-icons-5.svg" />
          <div
            className={`flex-1 relative leading-[24px] cursor-pointer mq450:text-base mq450:leading-[19px] ${selection === 5 ? 'font-bold' : ''
              }`}

            onClick={() => {
              handleNavigation('/profile');
              handleSide6();
            }}

          >
            Profile
          </div>
        </div>
      </div>
    </div>
  );
};

export default PractitionerSidebar;
