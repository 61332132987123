import { Button } from "@mui/material";
import Footer from "../components/Layout/Footer";
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { getPractitioner } from "../Actions/Consumer/GetPractitionerAction";
import { useDispatch, useSelector } from "react-redux";

const FirstLandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const USER = "user";
  const user = JSON.parse(localStorage.getItem(USER));
  const practitioner = useSelector((state) => state.practitioner.practitioner);
  
  useEffect(() => {
    if (user?.user?.id) {
      dispatch(getPractitioner(user.user.id));
    }
  }, [dispatch]);
 
  const handlePractitionerNavigate = () => {
    if (!user?.user?.id) {
    
      navigate("/practitioner-landing-page");
      return;
    }

    if (!practitioner) {
   
      return;
    }
    const categories = practitioner?.result[0]?.categories || [];
    console.log(categories,'check categories')
    console.log(practitioner, "practitioner dta in first ");
    
    
    if (! categories || (categories!==undefined && categories?.length === 0)) {
    
      navigate("/practitioner-profile-detailing");
     
    } else {
     
      navigate("/practitioner-landing-page");
    }
  };
  const handleConsumerNavigate = () => {
    navigate('/consumer-landing-page');
  };
  useEffect(() => {

  });
  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <section className="self-stretch relative bg-gray-300 flex flex-col items-start justify-start pt-0 px-0 pb-[95px] box-border gap-[109px] max-w-full text-center text-[72px] text-neutral-1 font-web-primary-h3-primary-dm-sans mq450:gap-[27px] mq750:gap-[54px] mq750:pb-[133px] mq750:box-border" style={{ height: '100vh', width: '100vw' }}>
        <video
          autoPlay
          loop
          muted
          className="absolute inset-0 object-cover w-full h-full"
        >
          <source src="/The-Healers-Hero-Section-Video.mp4" type="video/mp4" />
        </video>
        <div
          className="absolute inset-0 bg-black opacity-40"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
        ></div>
        <header className="self-stretch flex flex-row items-start justify-start mx-auto w-[1440px] py-6 px-[100px] top-[0] z-[99] sticky mq450:gap-[101px] mq450:pl-5 mq450:pr-5 mq450:box-border">
          <div className="w-60 flex flex-row items-end justify-start gap-[12.6px]">
          <img
    className="relative overflow-hidden"
    loading="lazy"
    alt="Logo"
    src="/ConsumerLogo.png" 
    style={{ width: '236px', height: '47px' }} 
  />
          </div>
        </header>
       
        <div className="self-stretch flex flex-grow items-center justify-center box-border max-w-full" style={{ height: '100%' }}>
          <div className="flex flex-col items-center justify-center w-full" style={{ height: '100%' }}>
            <div className="w-[698px] flex flex-col items-start justify-start gap-[16px] max-w-full mq750:gap-[8px]">
              <h1 className="m-0 self-stretch relative text-inherit leading-[110%] font-black font-inherit mq450:text-[43px] mq450:leading-[48px] mq750:text-[58px] mq750:leading-[63px]">
                Ready to transform your life?
              </h1>
              <div className="self-stretch flex flex-row items-start justify-start box-border max-w-full text-3xl mq750:pl-8 mq750:pr-8 mq750:box-border">
                <h3 className="flex-1 relative text-inherit leading-[160%] font-bold font-inherit inline-block max-w-full mq450:text-lgi mq450:leading-[31px] opacity-80">
                  Join Healers Inc today and take the first step towards a healthier, happier you.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="self-stretch flex flex-row items-start justify-center max-w-full text-left text-5xl text-neutral-1 font-web-primary-h3-primary-dm-sans">
        <div className="h-[500px] flex-1 relative max-w-full">
          <div className="absolute top-[0px] left-[0px] w-[50%] overflow-hidden flex flex-col items-start justify-start pt-14 px-[100px] pb-[287px] box-border bg-[url('/public/divpractitionernavigation@3x.png')] bg-cover bg-no-repeat bg-[top] min-h-[500px] max-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
              <h3 className="m-0 self-stretch relative text-inherit tracking-[0.5px] font-bold font-inherit mq450:text-lgi text-3xl">
                Join our community of healers and showcase your fitness and
                wellness services to a wide audience.
              </h3>
              <Button
                className="w-[206px] h-10 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "18",
                  background: "#E08E2B",
                  border: "#E08E2B solid 1px",
                  borderRadius: "4px",
                  "&:hover": { background: "#E08E2B" },
                  width: 206,
                  height: 40,
                }}
                onClick={handlePractitionerNavigate}
              >
                Join as Practitioner
              </Button>
            </div>
          </div>
          <div className="absolute top-[0px] left-[50%] w-[50%] overflow-hidden flex flex-col items-start justify-start pt-14 px-[100px] pb-[287px] box-border bg-[url('/public/divconsumernavigation@3x.png')] bg-cover bg-no-repeat bg-[top] min-h-[500px] max-w-full">
            <div className="self-stretch flex flex-col items-start justify-start py-0 pr-[11px] pl-0 gap-[24px]">
              <h3 className="m-0 self-stretch relative text-inherit tracking-[0.5px] font-bold font-inherit mq450:text-lgi text-3xl">
                Explore a variety of fitness and wellness services offered by
                our trusted providers to enhance your well-being.
              </h3>
              <Button
                className="w-[206px] h-10 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "18",
                  background: "#E08E2B",
                  border: "#E08E2B solid 1px",
                  borderRadius: "4px",
                  "&:hover": { background: "#E08E2B" },
                  width: 206,
                  height: 40,
                }}
                onClick={handleConsumerNavigate}
              >
                Explore Services
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="self-stretch flex flex-col items-center justify-center py-14 px-0 box-border gap-[56px] max-w-full text-center text-smi text-consumer-colors-monochromatic font-web-primary-h3-primary-dm-sans mq750:gap-[28px] mq750:pt-9 mq750:pb-9 mq750:box-border">
        <div className="w-[1240px] overflow-hidden flex flex-col items-center justify-center py-0 px-5 box-border gap-[24px] max-w-full">
          <b className="relative tracking-[1.51px] uppercase inline-block min-w-[128px]">
            Why choose us
          </b>
          <h1 className="m-0 w-[521px] relative text-17xl tracking-[-1.2px] leading-[48px] font-bold font-inherit text-gray-200 inline-block max-w-full mq450:text-3xl mq450:leading-[29px] mq750:text-10xl mq750:leading-[38px]">
            People choose us because we serve the best for everyone
          </h1>
        </div>
        <div className="w-[1240px] flex flex-row flex-wrap items-start justify-center py-0 px-[164px] box-border gap-[56px] min-h-[236px] max-w-full text-left text-2xl text-gray-200 mq1225:pl-[82px] mq1225:pr-[82px] mq1225:box-border mq750:gap-[28px] mq750:pl-[41px] mq750:pr-[41px] mq750:box-border">
          <div className="w-[428px] flex flex-row items-center justify-start py-0 pr-4 pl-0 box-border gap-[32px] max-w-full mq450:flex-wrap mq450:gap-[16px]">
            <img
              className="h-20 w-20 relative"
              loading="lazy"
              alt=""
              src="/icon.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-start min-w-[195px]">
              <b className="relative tracking-[-0.5px] leading-[32px] mq450:text-mid mq450:leading-[26px]">
                Booking and Scheduling
              </b>
              <div className="self-stretch relative text-mid tracking-[-0.2px] leading-[29px] text-gray-700 mix-blend-normal">
                Book appointments with confidence using our secure booking
                system.
              </div>
            </div>
          </div>
          <div className="w-[428px] flex flex-row items-center justify-start py-0 pr-[17px] pl-0 box-border gap-[32px] max-w-full mq450:flex-wrap mq450:gap-[16px]">
            <img
              className="h-[79px] w-[79px] relative"
              loading="lazy"
              alt=""
              src="/icon-1.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-start min-w-[195px]">
              <b className="relative tracking-[-0.5px] leading-[32px] mq450:text-mid mq450:leading-[26px]">
                Rating and Reviews
              </b>
              <div className="self-stretch relative text-mid tracking-[-0.2px] leading-[29px] text-gray-700 mix-blend-normal">
                Make informed decisions by reading reviews and ratings from
                other users.
              </div>
            </div>
          </div>
          <div className="w-[428px] flex flex-row items-center justify-start py-0 pr-[17px] pl-0 box-border gap-[32px] max-w-full mq450:flex-wrap mq450:gap-[16px]">
            <img
              className="h-[79px] w-[79px] relative"
              loading="lazy"
              alt=""
              src="/icon-1.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-start min-w-[195px]">
              <b className="relative tracking-[-0.5px] leading-[32px] mq450:text-mid mq450:leading-[26px]">
                Vendor Dashboard
              </b>
              <div className="self-stretch relative text-mid tracking-[-0.2px] leading-[29px] text-gray-700 mix-blend-normal">
                Access a comprehensive dashboard to manage bookings, track
                earnings.
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[32px] max-w-full mq450:gap-[16px] mq750:flex-wrap">
            <img
              className="h-[79px] w-[79px] relative"
              loading="lazy"
              alt=""
              src="/icon-1.svg"
            />
            <div className="flex flex-col items-start justify-start">
              <b className="relative tracking-[-0.5px] leading-[32px] mq450:text-mid mq450:leading-[26px]">
                Personalized Recommendations
              </b>
              <div className="w-[300px] relative text-mid tracking-[-0.2px] leading-[29px] text-gray-700 inline-block mix-blend-normal">
                Receive recommendations based on your past bookings and
                interests.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="self-stretch bg-consumer-colors-primary-1 flex flex-row items-center justify-center py-[50px] px-[100px] box-border gap-[56px] max-w-full text-left text-smi text-consumer-colors-monochromatic font-web-primary-h3-primary-dm-sans mq1225:flex-wrap mq1225:pl-[50px] mq1225:pr-[50px] mq1225:box-border mq750:gap-[28px] mq750:py-[91px] mq750:px-[25px] mq750:box-border">
        <div className="flex flex-col items-start justify-start gap-[32px] min-w-[690px] max-w-[690px] mq1225:flex-1 mq1050:min-w-full mq750:gap-[16px]">
          <div className="w-[589px] flex flex-col items-start justify-start gap-[8px] max-w-full">
            <b className="self-stretch relative tracking-[1.63px] uppercase">
             Healers Inc app
            </b>
            <h1 className="m-0 w-[568px] relative text-37xl tracking-[-1.8px] leading-[110%] font-bold font-inherit text-gray-200 inline-block max-w-full mq450:text-15xl mq450:leading-[37px] mq750:text-26xl mq750:leading-[49px]">
              Experience Wellness On the Go
            </h1>
          </div>
          <div className="self-stretch relative text-xl tracking-[-0.2px] leading-[180%] text-gray-700 mix-blend-normal mq450:text-base mq450:leading-[29px]">
            Take your wellness journey with you wherever you go with our mobile
            app. Seamlessly access our services, schedule appointments, and stay
            connected with your practitioner—all from the palm of your hand.
            With user-friendly navigation and convenient features, our app makes
            it easy to prioritize your well-being, no matter how busy life gets.
          </div>
        </div>
        <div className="w-[494px]">
          <img
            className="w-[494px] h-auto"
            loading="lazy"
            alt=""
            src="/Image.png"
          />
        </div>
      </section>
      <div className="w-full flex justify-center">
        <Footer />
      </div>
    </div>
  );
};

export default FirstLandingPage;
