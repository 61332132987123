import { Button } from "@mui/material";
import {
  Menu,
  MenuItem,
  TextField
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Logout, resetLogoutState } from "../../Actions/Auth/LogoutAction";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const USER = "user";
  const KEY_DEVICE_ID = "deviceId";
  const CONSUMER_ROLE = 0;
  const UserData = localStorage.getItem(USER)
    ? JSON.parse(localStorage.getItem(USER))
    : "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localDeviceId = localStorage.getItem(KEY_DEVICE_ID);
  const logout = useSelector((state) => state.logout.logout);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [deviceId, setDeviceId] = useState(localDeviceId ? localDeviceId : null);
  useEffect(() => {
    if (logout && logout !== "isLoading" && logout.length !== 0 && logout.status === true) {
      NavigateToHome();
      dispatch(resetLogoutState());
    }
  }, [logout]);
  useEffect(() => {
    // Set the deviceId when the component mounts
    const id = getDeviceID();
    setDeviceId(id);
  }, []);
  const NavigateToSignup = () => {
    navigate('/consumer-sign-up')
  }
  const NavigateToSignin = () => {
    navigate('/consumer-sign-in')
  }
  const handleLogoClick = () => {
    navigate('/');
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const NavigateToHome = () => {
    navigate("/");
    localStorage.removeItem('user');
    localStorage.clear();
  };
  const getDeviceID = () => {
    if (navigator && navigator.userAgent) {
      return navigator.userAgent;
    } else {
      return "default_device_id";
    }
  };
 
  const handleLogout = () => {
    if (deviceId) {
      const userId = UserData.user.id;
      dispatch(Logout(deviceId, userId));
    }
  };
  const NavigateToProfile = () => {
    navigate("/consumer-profile");
  };
  const NavigateToDashboard = () => {
    navigate('/dashboard');
  }
  return (
    <header className="self-stretch h-[108px] flex flex-row items-start justify-center py-6 px-5 box-border gap-[732.2px] z-[2] mq1225:gap-[366px] mq750:gap-[183px] mq450:gap-[92px]">
      <div className="w-[251.8px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border">
        <div className="self-stretch flex flex-row items-end justify-start gap-[13.2px] cursor-pointer" onClick={handleLogoClick}>
        <img
    className="relative overflow-hidden"
    loading="lazy"
    alt="Logo"
    src="/ConsumerLogo.png" 
    style={{ width: '236px', height: '47px' }} 
  />
        </div>
      </div>
      {(UserData && UserData.user) ?
        UserData.user.role === CONSUMER_ROLE ?
          <div className="self-stretch flex flex-row items-start justify-start gap-[16px]">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="flex items-center"
            >
              {UserData && UserData.user.profileImage ? (
                <img
                  className="ml-2"
                  width="41px"
                  height="41px"
                  src={UserData.user.profileImage}
                  alt="Avatar"
                />
              ) : (
                <img
                  className="ml-2"
                  width="41px"
                  height="41px"
                  src="/Rectangle 39977.svg"
                  alt="Avatar"
                />
              )}
              <span
                style={{ textTransform: "none" }}
                className="ml-2 text-base text-white font-inherit"
              >
                {UserData.user.fullName}
              </span>
              <svg
                className="ml-2"
                width="24px"
                height="24px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  d="M4 8l8 8 8-8"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="2"
                />
              </svg>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={NavigateToProfile}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
          :
          <div className="self-stretch w-[260px] flex flex-row items-start justify-start gap-[16px]">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >

              {UserData.user.profileImage ? (
                <img
                  className="ml-2"
                  width="41px"
                  height="41px"
                  src={UserData.user.profileImage}
                  alt="Avatar"
                />
              ) : (
                <img
                  className="ml-2"
                  width="41px"
                  height="41px"
                  src="/placeholder_profileImage.png"
                  alt="Avatar"
                />
              )}
              <span
                style={{ textTransform: "none" }}
                className="ml-2 text-base text-white font-inherit"
              >
                {UserData.user.fullName}
              </span>
              <svg
                className="ml-2"
                width="24px"
                height="24px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  d="M4 8l8 8 8-8"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="2"
                />
              </svg>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={NavigateToDashboard}>
                Dashboard
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        :

        <div className="self-stretch w-64 flex flex-row items-start justify-start gap-[16px]">
          <div className="w-[79px] flex flex-col items-start justify-start pt-[11px] px-0 pb-0 box-border">
            <Button
              className="self-stretch h-[38px]"
              disableElevation={true}
              variant="text"
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "18",
                borderRadius: "4px",
                height: 38,
              }}
              onClick={NavigateToSignin}
            >
              Login
            </Button>
          </div>
          <Button
            className="self-stretch flex-1 [filter:drop-shadow(0px_2px_0px_rgba(0,_0,_0,_0.02))]"
            variant="outlined"
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "18",
              borderColor: "#fff",
              borderRadius: "8px",
              "&:hover": { borderColor: "#fff" },
            }}
            onClick={NavigateToSignup}
          >
            Sign Up Now
          </Button>
        </div>
      }

    </header>
  );
};

export default Header;
