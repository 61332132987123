import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signUp, resetSignupState } from "../Actions/Auth/SignupAction"
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/SubComponent/Loader";


const ConsumerSignUp = () => {
  useEffect(() => {
    dispatch(resetSignupState());
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Practitioner_ROLE = 1;
  const signUpState = useSelector(state => state.signup);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: 0,
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  useEffect(() => {
    const UserData = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    if (UserData && UserData.user && UserData.user.role === Practitioner_ROLE) {
      navigate("/access-denied")
    }

  }, []);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  const handleSignUp = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const body = formData;
      dispatch(signUp(body));
    }
  };
  const validateForm = () => {
    let valid = true;
    const newErrors = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    };

    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
      valid = false;
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
      valid = false;
    }

    if (!isValidEmail(formData.email)) {
      newErrors.email = "Invalid Email Format";
      valid = false;
    }

    if (!isValidPassword(formData.password)) {
      newErrors.password = "Password must include: length greater than 8 characters, at least 1 uppercase, 1 numeric value and 1 special character ";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };
  const handleSignUpClick = () => {
    navigate("/consumer-sign-in")
  };
  const navigateToEmailVerification = () => {
    navigate("/consumer-sign-up-email-verification");
  };
  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-row items-center justify-center py-20 pr-20 pl-[163px] box-border gap-[165px] tracking-[normal] leading-[normal] text-left text-29xl text-m3-black font-web-primary-h3-primary-dm-sans mq450:gap-[41px] mq450:pl-5 mq450:box-border mq750:gap-[82px] mq750:pl-[81px] mq750:pr-10 mq750:box-border mq1125:flex-wrap">
      <div className="w-[432px] flex flex-col items-start justify-start px-0 pb-0 box-border min-w-[432px] max-w-full mq450: mq450:box-border mq750:min-w-full  mq1050:box-border mq1125:flex-1">
        <form
          className="m-0 self-stretch flex flex-col items-start justify-start gap-[32px] max-w-full mq450:gap-[16px]"
          onSubmit={handleSignUp}
        >
          <div className="relative text-base leading-[24px] text-lg font-web-primary-h3-primary-dm-sans text-m3-black text-left inline-block max-w-full">
            Enter your credentials to create an account
          </div>
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <TextField
              className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
              color="primary"
              label="First Name"
              placeholder="John"
              required={true}
              fullWidth={true}
              type="text"
              variant="standard"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
              sx={{ "& .MuiInputBase-root": { height: "53px" } }}
            />
            <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
              <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-h5-medium text-text-secondary text-left">
                Helper text
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <TextField
              className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
              color="primary"
              label="Last Name"
              placeholder="Adams"
              fullWidth={true}
              required={true}
              variant="standard"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
              sx={{ "& .MuiInputBase-root": { height: "53px" } }}
            />
            <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
              <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-h5-medium text-text-secondary text-left">
                Helper text
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <TextField
              className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
              color="primary"
              label="Email"
              placeholder="user@email.com"
              required={true}
              fullWidth={true}
              variant="standard"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ "& .MuiInputBase-root": { height: "53px" } }}
            />
            <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
              <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-h5-medium text-text-secondary text-left">
                Helper text
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <TextField
              className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full mq450:h-auto"
              color="primary"
              label="Password"
              required={true}
              fullWidth={true}
              variant="standard"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleShowPasswordClick}
                      aria-label="toggle password visibility"
                    >
                      <Icon>
                        {showPassword ? "visibility_off" : "visibility"}
                      </Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ "& .MuiInputBase-root": { height: "53px" } }}
            />
            <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
              <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-h5-medium text-text-secondary text-left">
                Helper text
              </div>
            </div>
          </div>
          <Button
            type="submit"
            className="self-stretch h-[60px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq450:pl-5 mq450:pr-5 mq450:box-border"
            variant="contained"
            onClick={handleSignUp}
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "18",
              background: "#f28500",
              border: "#f28500 solid 1px",
              borderRadius: "4px",
              marginTop: "10px",
              "&:hover": { background: "#f28500" },
              height: 60,
            }}
          >
            {signUpState.signUp === "isLoading" ? (
              <div className="mt-2">
                <Loader />
              </div>
            ) : signUpState.signUp.status && signUpState.signUp.status == true ? (
              navigateToEmailVerification()
            ) : (
              "Sign Up"
            )}
          </Button>
          <div className="flex flex-row items-start justify-start gap-[2px]">
            <div className="relative text-sm leading-[22px] font-web-primary-h3-primary-dm-sans text-gray-200 text-left mt-[6px]">
              Already have an account?
            </div>
            <Button
              variant="text"
              className="text-sm leading-[22px] font-semibold font-web-primary-h3-primary-dm-sans text-royalblue whitespace-nowrap"
              onClick={handleSignUpClick}
              style={{ textTransform: 'lowercase' }}
            >
              Sign In
            </Button>
          </div>
        </form>
      </div>
      <div>
      <img src="/Signup.png" width={'664'} height={'994'} />
      </div>
    </div>
  );
};

export default ConsumerSignUp;