import { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setSidebar } from "../../Actions/Consumer/SetSidebarTabAction";
import ConsumerImageChooser from "../SubComponent/ConsumerImageChooser";

const SidebarLight = ({
  className = "",
  personOutline,
  personOutlineIconMinWidth,
  sidebarTileBackgroundColor,
  tasksColor,
  sidebarTileBackgroundColor1,
  tasksColor1,
  sidebarTileBackgroundColor2,
  tasksColor2,
  inbox,
  image,
  onImageChange
}) => {
  const sidebarLightStyle = useMemo(() => {
    return {
      minWidth: personOutlineIconMinWidth,
    };

  }, [personOutlineIconMinWidth]);
  const userData = JSON.parse(localStorage.getItem('user'));

  const sidebarTileStyle = useMemo(() => {
    return {
      backgroundColor: sidebarTileBackgroundColor,
    };
  }, [sidebarTileBackgroundColor]);

  const tasksStyle = useMemo(() => {
    return {
      color: tasksColor,
    };
  }, [tasksColor]);

  const sidebarTile1Style = useMemo(() => {
    return {
      backgroundColor: sidebarTileBackgroundColor1,
    };
  }, [sidebarTileBackgroundColor1]);

  const tasks1Style = useMemo(() => {
    return {
      color: tasksColor1,
    };
  }, [tasksColor1]);

  const sidebarTile2Style = useMemo(() => {
    return {
      backgroundColor: sidebarTileBackgroundColor2,
    };
  }, [sidebarTileBackgroundColor2]);

  const tasks2Style = useMemo(() => {
    return {
      color: tasksColor2,
    };
  }, [tasksColor2]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sideBar = useSelector(state => state.sideBar.sideBar);
  const [num, setNum] = useState(parseInt(localStorage.getItem("activeTab")) || 0);


  const handleProfileClick = () => {
    setNum(0);
    dispatch(setSidebar(0));
    navigate('/consumer-profile');
  };

  const handleScheduleClick = () => {
    setNum(1);
    dispatch(setSidebar(1));
    navigate('/consumer-schedule');
  };

  const handleInboxClick = () => {
    setNum(2);
    dispatch(setSidebar(2));
    navigate('/consumer-inbox');
  };

  useEffect(() => {
    dispatch(setSidebar(num));
  }, [num, dispatch]);

  const handleImageChange = (newImage) => {
    onImageChange(newImage);
  };
  return (
    <div
      className={`w-[320px] h-[82vh] rounded-lg bg-whitesmoke-400 box-border overflow-hidden flex flex-col items-center justify-start py-[13px] px-[13px] gap-[10px] min-w-[322px] max-w-full text-left text-2xl text-darkslategray-200 font-web-secondary-body1 mq1050:flex-1 mq1050:pt-[34px] mq1050:pb-[34px] mq1050:box-border mq450:gap-[20px] mq450:pt-[22px] mq450:pb-[22px] mq450:box-border ${className}`}
      style={sidebarLightStyle}
    >

      <div className="relative h-80 flex  flex-col items-center justify-center">
        <ConsumerImageChooser
          image={image} onImageChange={handleImageChange}
        />
        <p className="mt-2 text-5xl font-web-primary-h3-primary-dm-sans text-[#294566]">
          {userData.user.fullName}
        </p>
      </div>
      <div className="w-[290px] flex flex-col items-center justify-center gap-[16px] text-base text-neutral-7">
        <div
          className={`self-stretch rounded-13xl flex flex-row items-start justify-between py-3 pr-[160px] pl-6 gap-[20px] cursor-pointer mq450:pr-5 mq450:box-border ${sideBar === 0 ? "bg-darkorange text-white" : ""
            }`}
          style={sidebarTileStyle}
          onClick={handleProfileClick}
        >
          <div className="bg-hitbox-1000 overflow-hidden flex flex-col items-center justify-center">
            <img
              className="w-6 h-6 relative overflow-hidden shrink-0"
              alt=""
              src={sideBar === 0 ? "/person-outline.svg" : personOutline}
            />
          </div>
          <div
            className="relative leading-[24px] font-semibold inline-block min-w-[49px] cursor-pointer"
            style={tasksStyle}
          >
            Profile
          </div>
        </div>
        <div
          className={`self-stretch rounded-13xl flex flex-row items-start justify-between py-3 pr-[140px] pl-6 gap-[20px] cursor-pointer mq450:pr-5 mq450:box-border ${sideBar === 1 ? "bg-darkorange text-white" : ""
            }`}
          onClick={handleScheduleClick}
          style={sidebarTile1Style}
        >
          <div className="bg-hitbox-1000 overflow-hidden flex flex-col items-center justify-center">
            <img
              className="w-6 h-6 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src={sideBar === 1 ? "/event_note2.svg" : "/event-note.svg"}
            />
          </div>
          <div
            className="relative leading-[24px] font-semibold inline-block min-w-[72px]"
            style={tasks1Style}
          >
            Schedule
          </div>
        </div>
        <div
          className={`self-stretch rounded-13xl flex flex-row items-start justify-between py-3 pr-[169px] pl-6 gap-[20px] cursor-pointer mq450:pr-5 mq450:box-border ${sideBar === 2 ? "bg-darkorange text-white" : ""
            }`}
          onClick={handleInboxClick}
          style={sidebarTile2Style}
        >
          <div className="bg-hitbox-1000 overflow-hidden flex flex-col items-center justify-center">
            <img
              className="w-6 h-6 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src={sideBar === 2 ? "/forum2.svg" : "/forum.svg"}
            />
          </div>
          <div
            className="relative leading-[24px] font-semibold inline-block min-w-[43px]"
            style={tasks2Style}
          >
            Inbox
          </div>
        </div>
      
      </div>
    </div>
  );
};

SidebarLight.propTypes = {
  className: PropTypes.string,
  personOutline: PropTypes.string,
  eventNote: PropTypes.string,
  forum: PropTypes.string,

  /** Style props */
  personOutlineIconMinWidth: PropTypes.any,
  sidebarTileBackgroundColor: PropTypes.any,
  tasksColor: PropTypes.any,
  sidebarTileBackgroundColor1: PropTypes.any,
  tasksColor1: PropTypes.any,
  sidebarTileBackgroundColor2: PropTypes.any,
  tasksColor2: PropTypes.any,

  /** Action props */
  onSidebarTileContainer1Click: PropTypes.func,
  onSidebarTileContainer2Click: PropTypes.func,
};

export default SidebarLight;
