import { GET_BOOKING_FAIL, GET_BOOKING_PENDING, GET_BOOKING_SUCCESS, GET_HISTORY_BOOKING_FAIL, GET_HISTORY_BOOKING_PENDING, GET_HISTORY_BOOKING_SUCCESS, GET_UPCOMING_BOOKING_FAIL, GET_UPCOMING_BOOKING_PENDING, GET_UPCOMING_BOOKING_SUCCESS, SET_MESSAGE } from "../Types"
import GetPractitionerBookingService from "../../services/GetPractitionerBookingService";
import { toast } from "react-toastify"

export const getPendingBooking = (practitionerId, currentPagePending, fromDate, toDate, selectedDuration, selectedServiceType, serviceIds) => async (dispatch) => {
  dispatch({
    type: GET_BOOKING_PENDING,
    payload: { getBooking: "isLoading" },
  });
  return await GetPractitionerBookingService.getPractitionerPendingBookings(practitionerId, currentPagePending, fromDate, toDate, selectedDuration, selectedServiceType, serviceIds)
    .then(
      (data) => {
        console.log(data);
        if (data.status == 200) {
          dispatch({
            type: GET_BOOKING_SUCCESS,
            payload: { getBooking: data.data },
          })
        }
        else {
          dispatch({
            type: GET_BOOKING_FAIL,
          });
          toast.error(data.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("error ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: GET_BOOKING_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        toast.error(error);
          return Promise.reject();
        }
      )
      .catch((error) => {
        toast.error(error)
        
      });
};


export const getUpcomingBooking = (practitionerId, currentPageUpcoming, fromDate, toDate, serviceIds) => async (dispatch) => {
  dispatch({
    type: GET_UPCOMING_BOOKING_PENDING,
    payload: { getUpcomingBooking: "isLoading" },
  });
  return await GetPractitionerBookingService?.getPractitionerUpcomingBooking(practitionerId, currentPageUpcoming,fromDate, toDate, serviceIds)
    .then(
      (data) => {
        console.log(data);
        if (data.status == 200) {
          dispatch({
            type: GET_UPCOMING_BOOKING_SUCCESS,
            payload: { getUpcomingBooking: data.data },
          })
        }
        else {
          dispatch({
            type: GET_UPCOMING_BOOKING_FAIL,
          });
          toast.error(data.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("error ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: GET_UPCOMING_BOOKING_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getHistoryBooking = (practitionerId, currentPageHistory,fromDate, toDate , bookingStatus, serviceIds) => async (dispatch) => {
  dispatch({
    type: GET_HISTORY_BOOKING_PENDING,
    payload: { getHistoryBooking: "isLoading" },
  });
  return await GetPractitionerBookingService.getPractitionerHistoryBooking(practitionerId, currentPageHistory, fromDate, toDate, serviceIds, bookingStatus)
    .then(
      (data) => {
        console.log(data);
        if (data.status == 200) {
          dispatch({
            type: GET_HISTORY_BOOKING_SUCCESS,
            payload: { getHistoryBooking: data.data },
          })
        }
        else {
          dispatch({
            type: GET_HISTORY_BOOKING_FAIL,
          });
          toast.error(data.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("error ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: GET_HISTORY_BOOKING_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

