import {
    LOGOUT_FAIL,
    LOGOUT_PENDING,
    LOGOUT_SUCCESS,
    RESET_LOGOUT_STATE,
    SET_MESSAGE,
} from "../Types";
import UserService from "../../services/user.service";
import { toast } from "react-toastify";

export const Logout = (deviceId, userId) => async (dispatch) => {
    dispatch({
        type: LOGOUT_PENDING,
        payload: { logout: "isLoading" },
    });

    return await UserService.logout(deviceId, userId)
        .then((data) => {
            if (data.data.status === true) {
                dispatch({
                    type: LOGOUT_SUCCESS,
                    payload: { logout: data.data },
                });
            } else {
                dispatch({
                    type: LOGOUT_FAIL,
                });
                toast.error(data.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            return Promise.resolve();
        },
            (error) => {
                console.log("error ", error);
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                dispatch({
                    type: LOGOUT_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
                toast.error(error);
                return Promise.reject();
            }
        )
        .catch((error) => {
            toast.error(error);
        });
};
export const resetLogoutState = () => ({
    type: RESET_LOGOUT_STATE,
});