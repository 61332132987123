
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const AccessDenied = () => {
    const navigate = useNavigate();
    const USER = "user";
    const PRACTITIONER_ROLE = 1;
    const CONSUMER_ROLE = 0;
    const user = localStorage.getItem(USER)
        ? JSON.parse(localStorage.getItem(USER))
        : null;
    const handleButtonClick = () => {
        if (!user) {
            navigate("/")
        } else if (user && user.user.role === PRACTITIONER_ROLE) {
            navigate("/dashboard")
        } else if (user && user.user.role === CONSUMER_ROLE) {
            navigate("/search-results-services")
        }
    };
    return (
        <div className="w-full relative shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)] bg-neutral-1 overflow-hidden flex flex-col items-start justify-start pt-14 px-14 pb-[284px] box-border gap-[178px] leading-[normal] tracking-[normal] mq450:gap-[44px] mq750:gap-[89px] mq750:pl-7 mq750:pr-7 mq750:box-border">
            <div className="w-[251.8px] flex flex-row items-end justify-start gap-[13.2px]">
                <img
                    className="relative overflow-hidden"
                    loading="lazy"
                    alt="Logo"
                    src="/PractLogo.png"
                    style={{ width: '236px', height: '47px' }}
                />
            </div>
            <section
                className={`self-stretch flex flex-row items-start justify-center max-w-full text-center font-web-secondary-body1`}
            >
                <div className="w-[601px] flex flex-col items-center justify-start gap-[64px] max-w-full mq450:gap-[16px] mq675:gap-[32px]">
                    <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
                        <div className="min-h-[162px] min-w-[372px] flex flex-col items-center justify-between relative max-w-full">
                            <div className="min-h-[162px] min-w-[372px]">
                                <img
                                    className="h-[162px] w-[372px] relative max-w-full"
                                    src="/accessdeined_image.svg"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-start">

                        <div className="text-6xl text-neutral-9">
                            <h1 className="text-41xl text-neutral-9 m-0 p-0">
                                Access denied
                            </h1>
                        </div>
                        <div className="text-3xl text-neutral-7 mq450:text-lgi mt-2">
                            Seems like you are not authorized to access this page.
                        </div>
                    </div>
                    <div className="self-stretch h-[52px] flex flex-row items-start justify-center py-0 px-5 box-border">
                        <Button
                            className="self-stretch w-[183px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                            variant="contained"
                            onClick={handleButtonClick}
                            sx={{
                                textTransform: "none",
                                color: "#fff",
                                fontSize: "18",
                                background: "#f28500",
                                border: "#f28500 solid 1px",
                                borderRadius: "4px",
                                "&:hover": { background: "#f28500" },
                                width: 183,
                            }}
                        >
                            Take me back
                        </Button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AccessDenied;
