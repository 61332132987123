import React, { useState, useEffect } from 'react';
import {
  Button,
} from "@mui/material";
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { confirmSignUp, resetconfirmState } from '../Actions/Auth/ConfirmSignupAction';
import Loader from "../components/SubComponent/Loader";
import { resendOtp } from '../Actions/Auth/ResendOtpAction';
const PractitionerSignUpVerif = () => {
  useEffect(() => {
    dispatch(resetconfirmState());
  }, []);
  const [otp, setOtp] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();
  const formData = useSelector(state => state.signup.UserData);
  const confirmSignupState = useSelector(state => state.confirm_signup);
  const navigate = useNavigate();

  const handleChange = (otp) => {
    setOtp(otp);
  };
  const handleBackClick = () => {
    navigate("/practitioner-sign-up");
  };
  const handleVerifyClick = async () => {
    const body =
    {
      "email": formData.email,
      "verificationCode": otp,
      "password": formData.password,
      "userType": 1
    }
    dispatch(confirmSignUp(body));
  }
  const handleResendClick = async () => {
    dispatch(resendOtp(formData?.email));
  }
  const confirmHandle = () => {
    localStorage.setItem("user", JSON.stringify(confirmSignupState.UserData));
    navigate("/practitioner-profile-detailing");
  };
  return (
    <div className="w-full relative bg-m3-sys-light-on-primary overflow-hidden flex flex-row items-start justify-start py-10 pr-10 pl-[140px] box-border gap-[127px] leading-[normal] tracking-[normal] mq750:gap-[63px] mq750:pl-[70px] mq750:box-border mq450:gap-[32px] mq450:pl-5 mq450:box-border mq1125:flex-wrap">
      <div className="w-[400px] flex flex-col items-start justify-start pt-8 px-0 pb-0 box-border min-w-[400px] max-w-full mq750:min-w-full mq1125:flex-1">
        <div className="self-stretch flex flex-col items-start justify-start gap-[199px] mq450:gap-[99px] mb-36">
          <div className="w-60 flex flex-row items-end justify-start gap-[12.6px]">
            <img
              className="relative overflow-hidden"
              loading="lazy"
              alt="Logo"
              src="/PractLogo.png"
              style={{ width: '236px', height: '47px' }}
            />
          </div>
        </div>
        <div className="w-[400px] flex flex-col items-start justify-start px-0 pb-0 box-border min-w-[400px] max-w-full text-xs text-neutral-10 mq750:pt-[155px] mq750:box-border mq750:min-w-full mq1125:flex-1 mt-8">
          <div className="self-stretch flex flex-col items-start justify-start gap-[32px] mq450:gap-[16px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-xl">
              <h2 className="m-0 relative text-inherit leading-[30px] font-bold font-inherit inline-block min-w-[116px] mq450:text-base mq450:leading-[24px]">
                Verify Email
              </h2>
              <div className="self-stretch relative text-base leading-[24px]">
                <span>{`A 6 digit OTP has been sent to `}</span>
                <b>{formData?.email}</b>
                {". "}
                <span>Please verify to continue.</span>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px] text-text-secondary mq450:flex-wrap">
              <div className="w-10 flex flex-row items-start justify-start">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  separator={<span>&nbsp;</span>}
                  isInputNum
                  inputStyle={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    fontSize: "18px",
                    textAlign: "center",
                    outline: "none",
                  }}
                  containerStyle={{ display: "flex", gap: "20px" }}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end text-center">
              <div className="flex flex-row items-start justify-start gap-[2px]">
                <div className=" relative leading-[18px] mt-2">
                  Didn’t receive the code?
                </div>
                <Button
                  className="[text-decoration:underline] relative leading-[18px] text-mediumblue inline-block min-w-[21px] "
                  onClick={handleResendClick}
                  style={{ textTransform: 'lowercase' }}
                >
                  Resend
                </Button>
              </div>
            </div>
            <Button
              className="self-stretch h-[60px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq450:pl-5 mq450:pr-5 mq450:box-border"
              variant="contained"
              disabled={!(otp.length == 6)}
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "18",
                background: "#006a6a",
                borderRadius: "4px",
                "&:hover": { background: "#006a6a" },
                height: 60,
              }}
              onClick={handleVerifyClick}
            >
              {confirmSignupState.confirmSignUp === "isLoading" ? (
                <div className="mt-2">
                  <Loader></Loader>
                </div>
              ) : confirmSignupState.confirmSignUp.status &&
                confirmSignupState.confirmSignUp.status === true ? (
                confirmHandle()
              ) : (
                "Verify Email"
              )}
            </Button>
            <Button
              className="w-[167px] h-[42px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
              startIcon={
                <img width="16px" height="16px" src="/navigate-before.svg" />
              }
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#434343",
                fontSize: "14",
                background: "#fff",
                border: "#d9d9d9 solid 1px",
                borderRadius: "4px",
                "&:hover": { background: "#fff" },
                width: 167,
                height: 42,
              }}
              onClick={handleBackClick}
            >
              Back to Sign Up
            </Button>
          </div>
        </div>
      </div>
      <div className="h-[944px] flex-1 relative min-w-[476px] max-w-full mq750:min-w-full">
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[93px] rounded-3xl max-h-full w-[640px] object-cover"
          loading="lazy"
          alt=""
          src="/chrislee70l1tdai6rmunsplash-2@2x.png"
        />
        <div className="absolute top-[634px] left-[0px] backdrop-filter backdrop-blur-[22.2px] rounded-[50%] bg-teal-300 bg-opacity-35 box-border w-[187px] h-[187px] z-[1] border-[0px] border-solid border-teal-200" />
      </div>
    </div>
  );
};

export default PractitionerSignUpVerif;