import {ADD_BOOKING_FAIL, ADD_BOOKING_PENDING, ADD_BOOKING_SUCCESS, SET_MESSAGE, RESET_ADD_BOOKING} from "../Types"
import AddBookingService from "../../services/AddBookingService";
import { toast } from "react-toastify"


export const AddBooking = (body) => async (dispatch) => {
    dispatch({
      type: ADD_BOOKING_PENDING,
      payload: { addBooking : "isLoading" },
    });
    return await AddBookingService.createConsumerBooking(body)
      .then(
        (data) => {
          console.log(data);
          if(data.data.status === true)
          {
            dispatch({
                type: ADD_BOOKING_SUCCESS,
                payload: { addBooking: data.data },
              })
              toast.success("Booking Added Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            else
            { dispatch({
                type: ADD_BOOKING_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: ADD_SCHEDULE_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          toast.error(error);
          return Promise.reject();
        }
      )
      .catch((error) => {
        toast.error(error)
        
      });
  };

  export const resetAddBooking = () => ({
    type: RESET_ADD_BOOKING,
  });

  