import {UPDATE_SERVICE_FAIL, UPDATE_SERVICE_PENDING, UPDATE_SERVICE_SUCCESS,RESET_UPDATE_SERVICE, SET_MESSAGE} from "../Types"
import addServiceService from "../../services/addServiceService";
import { toast } from "react-toastify"


export const updateService = (body) => async (dispatch) => {
    dispatch({
      type: UPDATE_SERVICE_PENDING,
      payload: { updateService : "isLoading" },
    });
    return await addServiceService.updateService(body)
      .then(
        (data) => {
          console.log(data);
          if(data.status == 200)
          {
            dispatch({
                type: UPDATE_SERVICE_SUCCESS,
                payload: { updateService: data.data },
              })
            }
            else
            { dispatch({
                type: UPDATE_SERVICE_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: UPDATE_SERVICE_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          toast.error(error);
          return Promise.reject();
        }
      )
      .catch((error) => {
        toast.error(error)
        
      });
  };

  export const resetUpdateService = () => ({
    type: RESET_UPDATE_SERVICE,
  });

