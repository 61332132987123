import axios from "axios";
import { BASE_URL } from "../Constants";
import authHeader from './AuthHeader';
import { interceptor } from '../services/Interceptor';
const API_URL = BASE_URL;


class UserService {
 
  async addFcmToken(fcmBody) {
    interceptor();
    const headers = authHeader();
    console.log("Auth Headers: ", headers);  // Log the headers to check their validity
    return axios.post(API_URL + "Auth/Addfcm", fcmBody, {
      headers: headers,
    });
  }
  
  //logout
  // async logout(deviceId, userId) {
  //   const url = `${API_URL}Auth/Logout?deviceId=${deviceId}`;
  //   return axios.post(url, null, {
  //     headers: authHeader(),
  //   });
  // }
  async logout(deviceId, userId) {
    const url = `${API_URL}Auth/Logout?deviceId=${encodeURIComponent(deviceId)}&userId=${encodeURIComponent(userId)}`;
    return axios.post(url, null, {
      headers: authHeader(),
    });
}

  //get notification
  async getNotifications(userId) {
    interceptor();
    const timeZoneOffset = ((new Date().getTimezoneOffset().toString()) / 60) * -1;
    const url = `${API_URL}Notification/GetNotifications?userId=${userId}`;
    return axios.get(url, null, {
      headers: authHeader(),
      'TimeZoneOffSet': timeZoneOffset,
    });
  }
  async sendChatNotifications(userId, title, body) {
    interceptor();
  
    const url = `${API_URL}Notification/SendChatNotification?userId=${userId}&title=${encodeURIComponent(title)}&body=${encodeURIComponent(body)}`;
    
    return axios.post(url, null, {
      headers: authHeader(),
    });
  }
  
  //delete notification
  async deleteNotifications(notificationIds) {
    interceptor();
    const url = `${API_URL}Notification/DeleteNotification`;
    return axios.delete(url, {
      headers: {
        ...authHeader(),
      },
      data: notificationIds
    });
  }
  //update notification
  async updateNotifications(updateNotificationIds) {
    interceptor();
    const url = `${API_URL}Notification/UpdateStatus`;
    return axios.put(url, updateNotificationIds, {
      headers: {
        ...authHeader(),
      },
    });
  }
}

export default new UserService();