import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Loader from "../components/SubComponent/Loader";
import addYears from 'date-fns/addYears';
import isFuture from 'date-fns/isFuture';
import {
  Typography,
  TextField,
  Button,
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getAll } from "../Actions/Catagories/GetCategoriesAction";
import {createPreferences} from "../Actions/Consumer/ConsumerPreferenceAction"
import ConsumerHeader from "../components/Layout/ConsumerHeader";
import ConsumerPreferencesSide from "../components/Layout/ConsumerPreferencesSide";

const ConsumerPreferences1 = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAll());
  }, []);
   const USER ="user";
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem(USER));
  const selection = useSelector((state) => state.selected.selected);
  const [selectedDate, setSelectedDate] = useState(null);
  const [gender, setGender] = useState(null);
  const [Wunit, setWUnit] = useState('0');
  const [Hunit, setHUnit] = useState('0');
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const allCategories = useSelector((state) => state.getCategories.getAll);
  const createState = useSelector((state) => state.selected);
  const handleWUnit = (event, newWUnit) => {
    if (Wunit !== newWUnit) {
      setWUnit(newWUnit);
    }
  };
  const handleHUnit = (event, newHUnit) => {
    if (Hunit !== newHUnit) {
      setHUnit(newHUnit);
    }
  };
  const handleDateChange = (date) => {
    if (isFuture(date)) {
      setSelectedDate(null);
      setErrorMessage('The date cannot be in the future.');
    } else if (date > minDate) {
      setSelectedDate(null);
      setErrorMessage('You must be at least 16 years old.');
    } else {
      setErrorMessage('');
      setSelectedDate(date);
    }
  };
  const setToMale = () => {
    setGender(0);
  };
  const setToFemale = () => {
    setGender(1);
  };
  const handleWeight = (event) => {
    setWeight(event.target.value);
  };
  const handleHeight = (event) => {
    setHeight(event.target.value);
  };
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleCategoryClick = (categoryId) => {
    const index = selectedCategories.indexOf(categoryId);
    if (index === -1) {
      setSelectedCategories([...selectedCategories, categoryId]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((id) => id !== categoryId)
      );
    }
  };
  const isDisabled = !UserData.user.id || gender === null || !selectedDate || !weight || !height || selectedCategories.length === 0 || Wunit === null || Hunit === null;
  const createConsumer = () =>
  {
    const body = {
      'id':  UserData.user.id,
      'gender': gender,
      'dateOfBirth': selectedDate,
      'weight': parseInt(weight,10),
      'height': parseInt(height,10),
      'selectedCategoryIds': selectedCategories,
      "weightUnit": parseInt(Wunit,10),
      "heightUnit": parseInt(Hunit,10),
    }
    dispatch(createPreferences(body));
  };
  const navigateToNext = () => {
    navigate("/search-results-practitioners");
  };
  const minDate = addYears(new Date(), -16);
  
  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[75px] box-border gap-[80px] leading-[normal] tracking-[normal] text-center text-lg text-neutral-8 font-web-primary-h3-primary-dm-sans mq750:gap-[40px] mq450:gap-[20px]">
      <ConsumerHeader></ConsumerHeader>
      <div className="w-[963px] flex flex-row items-start justify-start py-0 box-border max-w-full ml-32">
        <div className="flex flex-row items-start justify-center gap-[56px] max-w-full mq750:flex-wrap mq750:gap-[28px] relative">
          <ConsumerPreferencesSide></ConsumerPreferencesSide>
          {selection === "gender" && (
            <div className="flex flex-col items-start justify-start gap-[32px] min-w-[487px] max-w-full text-left text-13xl text-gray-800 mq750:flex-1 mq750:gap-[16px] mq750:min-w-full">
              <div className="flex flex-col items-start justify-start gap-[16px] max-w-full">
                <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit inline-block max-w-full mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
                  What is your gender?
                </h1>
                <div className="relative text-base leading-[24px]">
                  To provide you the best experience we need to know your
                  gender.
                </div>
              </div>
              <div className="overflow-hidden flex flex-row items-start justify-center gap-[44px] text-base mq450:gap-[22px]">
                <Button
                  className="rounded-lg bg-ghostwhite-200 overflow-hidden flex flex-col items-center justify-start py-4 px-8 gap-[8px]"
                  type="button"
                  onClick={setToMale}
                  style={{ border: gender == "0" ? '2px solid #3f51b5' : 'none' }}
                >
                  <img
                    className="w-16 h-16 relative object-contain"
                    loading="lazy"
                    alt=""
                    src="/image-102@2x.png"
                  />
                  <div className="relative leading-[24px] inline-block min-w-[35px]">
                    Male
                  </div>
                </Button>
                <Button
                  className="rounded-lg bg-ghostwhite-200 overflow-hidden flex flex-col items-center justify-start py-4 px-8 gap-[8px]"
                  type="button"
                  onClick={setToFemale}
                  style={{ border: gender == "1" ? '2px solid #3f51b5' : 'none' }}
                >
                  <img
                    className="w-16 h-16 relative object-contain"
                    alt=""
                    src="/image-102-1@2x.png"
                  />
                  <div className="relative leading-[24px] inline-block min-w-[53px]">
                    Female
                  </div>
                </Button>
              </div>
            </div>
          )}
          {selection === "date" && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="flex flex-col items-start justify-start gap-[32px] min-w-[487px] max-w-full text-left text-13xl text-gray-800 mq750:flex-1 mq750:gap-[16px] mq750:min-w-full">
              <div className="flex flex-col items-start justify-start gap-[16px] max-w-full">
                <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit inline-block max-w-full mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
                  How old are you?
                </h1>
                <div className="relative text-base leading-[24px]">
                  This will help us create a personalized experience.
                </div>
                <DatePicker
                  label="Date of Birth"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errorMessage}
                      helperText={errorMessage}
                      className="border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 min-w-full mt-2"
                    />
                  )}
                  maxDate={new Date()}
                />
                {errorMessage && (
                  <Typography color="error" variant="body2" className="mt-2">
                    {errorMessage}
                  </Typography>
                )}
              </div>
            </div>
          </LocalizationProvider>
          )}
          {selection === "physical" && (
            <div className="flex-1 flex flex-col items-start justify-start gap-[32px] max-w-[calc(100%_-_236px)] text-left text-base text-gray-800 mq750:gap-[16px] mq750:max-w-full">
              <div className="flex flex-col items-start justify-start gap-[16px] max-w-full text-13xl">
                <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
                  Let us know your weight and height
                </h1>
                <div className="relative text-base leading-[24px] inline-block max-w-full">
                  This will help us create your personalized experience.
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full">
                <div className="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap">
                  <div className="relative leading-[24px] font-semibold inline-block min-w-[55px]">
                    Weight
                  </div>
                  <div className="w-[120px] rounded-3xl bg-neutral-light-light flex flex-row items-center justify-center p-1 box-border text-center text-xs text-neutral-dark-light font-heading-h5">
                    <ToggleButtonGroup
                      value={Wunit}
                      exclusive
                      onChange={handleWUnit}
                      aria-label="text alignment"
                    >
                      <ToggleButton
                        className="h-[31px] flex-1"
                        value="0"
                        aria-label="left aligned"
                      >
                        kg
                      </ToggleButton>
                      <ToggleButton
                        className="h-[31px] flex-1"
                        value="1"
                        aria-label="centered"
                      >
                        lb
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
                <div className="self-stretch shadow-[0px_0px_0px_2px_rgba(24,_144,_255,_0.2)] rounded-sm bg-neutral-1 box-border overflow-hidden flex flex-row flex-wrap items-start justify-start min-w-[250px] max-w-full [row-gap:20px] border-[1px] border-solid border-primary-6 mq750:overflow-x-auto">
                  <TextField
                    className="[border:none] bg-[transparent] h-10 flex-1 font-h5-medium text-base text-gray-900 min-w-[300px] max-w-full"
                    variant="outlined"
                    placeholder="70"
                    type="number"
                    value={weight}
                    onChange={handleWeight}
                    sx={{ "& .MuiInputBase-root": { height: "40px" } }}
                  />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full">
                <div className="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap">
                  <div className="relative leading-[24px] font-semibold inline-block min-w-[51px]">
                    Height
                  </div>
                  <div className="w-[120px] rounded-3xl bg-neutral-light-light flex flex-row items-center justify-center p-1 box-border text-center text-xs text-neutral-dark-light font-heading-h5">
                    <ToggleButtonGroup
                      value={Hunit}
                      exclusive
                      onChange={handleHUnit}
                      aria-label="text alignment"
                    >
                      <ToggleButton
                        className="h-[31px] flex-1"
                        value="0"
                        aria-label="left aligned"
                      >
                        in
                      </ToggleButton>
                      <ToggleButton
                        className="h-[31px] flex-1"
                        value="1"
                        aria-label="centered"
                      >
                        cm
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
                <div className="self-stretch shadow-[0px_0px_0px_2px_rgba(24,_144,_255,_0.2)] rounded-sm bg-neutral-1 box-border overflow-hidden flex flex-row flex-wrap items-start justify-start max-w-full [row-gap:20px] border-[1px] border-solid border-primary-6 mq750:overflow-x-auto">
                  <TextField
                    className="[border:none] bg-[transparent] flex-1 font-h5-medium text-base text-gray-900 min-w-[520px] max-w-full"
                    variant="outlined"
                    placeholder="70"
                    value={height}
                    onChange={handleHeight}
                    type="number"
                    sx={{ "& .MuiInputBase-root": { height: "40px" } }}
                  />
                </div>
              </div>
            </div>
          )}
          {selection === "wellness" && (
            <div className="flex-1 flex flex-col items-start justify-start gap-[32px] max-w-[calc(100%_-_236px)] text-left text-13xl text-gray-800 mq750:gap-[16px] mq750:max-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full">
                <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
                  What would you like to focus on ?
                </h1>
                <div className="relative text-base leading-[24px] inline-block max-w-full">
                  This will help us create your personalized experience.
                </div>
              </div>
              <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 pr-[59px] pl-0 box-border gap-[8px] min-h-[232px] text-base mq1050:pr-[29px] mq1050:box-border">
                {allCategories.map((category) => (
                  <Button
                    key={category.id}
                    className="h-28 w-[183px]"
                    disableElevation={true}
                    variant={
                      selectedCategories.includes(category.id)
                        ? "contained"
                        : "outlined"
                    } 
                    sx={{
                      textTransform: "none",
                      color: selectedCategories.includes(category.id)
                        ? "#ffffff"
                        : "#18181b", 
                      fontSize: "16",
                      background: selectedCategories.includes(category.id)
                        ? "#6200ee"
                        : "#f4f1fc",
                      borderRadius: "8px",
                      width: 183,
                      height: 112,
                    }}
                    onClick={() => handleCategoryClick(category.id)} 
                  >
                    {category.displayName}
                  </Button>
                ))}
              </div>
            </div>
          )}
          {selection === "wellness" && (
            <div className="absolute bottom-2 right-28">
              <Button
                className="h-[52px] w-[209px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "18",
                  background: "#f28500",
                  borderRadius: "44px",
                  "&:hover": { background: "#f28500" },
                  width: 209,
                  height: 52,
                }}
                onClick={createConsumer}
                disabled = {isDisabled}
              >
                {createState.create === "isLoading" ? (
              <div className="mt-2">
                <Loader />
              </div>
            )
            : createState.create && createState.create.status == true ? (
              navigateToNext() 
            )  : (
              "Explore Services"
            )}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsumerPreferences1;
