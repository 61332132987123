import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Badge, IconButton } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { getNotifications, updateNotifications, deleteNotifications } from "../../Actions/NotificationsAction";

const PractitionerNotification = () => {
    const dispatch = useDispatch();
    const USER = "user";
    const UserData = JSON.parse(localStorage.getItem(USER));
    const userId = UserData.user.id;
    const [anchorEl, setAnchorEl] = useState(null);
   
    const [updateNotificationIds, setUpdateNotificationIds] = useState([]);
    const [notificationIds, setNotificationIds] = useState([]);
    
    const open = Boolean(anchorEl);
    const notifications = useSelector(state => state.getNotifications.getNotifications);

   
    useEffect(() => {
        if (notifications && notifications !== "isLoading" && notifications.result) {
            const ids = notifications.result.map(notification => notification.id);
            setNotificationIds(ids);
            const updateIds = notifications.result
                .filter(notification => notification.status === 0)
                .map(notification => notification.id);
            setUpdateNotificationIds(updateIds);
        }
    }, [notifications])
    const fetchNotifications = () => {
        if (userId) {
            dispatch(getNotifications(userId));
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    useEffect(() => {
        if (open && updateNotificationIds.length > 0) {
            dispatch(updateNotifications(updateNotificationIds))
                .then(() => {
                    dispatch(getNotifications(userId));
                })
        }
    }, [open, dispatch]);
    const handleClearAll = () => {
        if (notificationIds) {
            dispatch(deleteNotifications(notificationIds))
                .then(() => {
                    dispatch(getNotifications(userId));
                })
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const getTimeAgo = (dateTime) => {
        const now = new Date();
        const notificationTime = new Date(dateTime);
        const diffMilliseconds = now - notificationTime;
        const diffSeconds = Math.floor(diffMilliseconds / 1000);

        if (diffSeconds < 60) {
            return `${diffSeconds} sec ago`;
        }

        const diffMinutes = Math.floor(diffSeconds / 60);
        if (diffMinutes < 60) {
            return `${diffMinutes} min ago`;
        }

        const diffHours = Math.floor(diffMinutes / 60);
        return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    };

    return (
        <div>
            <IconButton
                aria-label="show notifications"
                aria-controls={open ? "notification-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <Badge badgeContent={notifications.totalCount} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                id="notification-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "notification-button",
                }}
            >
                <div className="w-[480px] h-[700px] rounded-3xs">
                    <div className="flex flex-row items-center justify-between h-[70px] px-5 border-b border-solid border-gray-300 shadow-lg">
                        <span className="font-bold text-xl">Notifications</span>
                        <span
                            className={`text-blue-500 cursor-pointer ${notifications.result && notifications.result.length === 0 ? 'opacity-50 pointer-events-none' : ''}`}
                            onClick={handleClearAll}
                        >
                            Clear all
                        </span>
                    </div>
                    {notifications && notifications !== "isLoading" && notifications.result && notifications.result.length > 0 && (
                        notifications.result.map((notification, index) => (
                            <div key={index} className="flex-1 px-5 py-4 border-b border-solid border-gray-300">
                                <div className="border-b py-2">
                                    <div className="text-black-600 font-semibold mb-1">{notification.title}</div>
                                    <div className="text-grey-600 mb-2">
                                        {notification.body}
                                    </div>
                                    <div className="text-gray-600 text-sm">
                                        {getTimeAgo(notification.dateTime)}
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                    {notifications && notifications !== "isLoading" && notifications.result && notifications.result.length === 0 && (
                        <div className="flex flex-grow justify-center items-center h-[200px]">
                            <div className="px-5 py-4">
                                <div className="border-b py-2">
                                    <div className="text-grey-600 mb-2 text-center">
                                        No notification to show
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Menu>
        </div>
    );
};

export default PractitionerNotification;