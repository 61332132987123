import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addPractitionerTag, resetProfileDetailing } from '../../Actions/Practitioner/ProfileDetailingAction';
import { Box, Button, Chip, Icon, IconButton, LinearProgress, Stack, TextField } from '@mui/material';

import { toast } from 'react-toastify';
import Loader from './Loader';

const ProfileDetailTags = ({setProfileDetails}) => {
    const USER = "user";
    const dispatch = useDispatch();
    const [categoryTags, setCategoryTags] = useState([]);
    const user = JSON.parse(localStorage.getItem(USER));
    const practitionerData = useSelector((state) => state.practitioner.practitioner)
    const userCategories = practitionerData && practitionerData.length !== 0 && practitionerData.result && practitionerData.result[0].categories;
  
    const profileDetailingState = useSelector((state) => state.profileDetailing);
  
    console.log(
      userCategories, 'categories', categoryTags, 'tags'
    )
    const handleNextClick = async () => {
        const categoriesWithInsufficientTags = Object.entries(categoryTags)
          .filter(([categoryId, tags]) => tags.length < 1)
          .map(([categoryId]) => categoryId);
        if (categoryTags?.length === 0) {
          toast.error("You must add atleast 1 tags", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return
        }
    
        if (categoriesWithInsufficientTags.length > 0) {
          toast.error("You must add atleast 1 tags", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          const body = {
            userId: user.user.id,
            categoriesWithTags: Object.entries(categoryTags).map(
              ([categoryId, tags]) => ({
                categoryId,
                tags: tags.join(", "),
              })
            ),
          };
          dispatch(addPractitionerTag(body))
            .then(() => {
    
              console.log(categoryTags, 'tags added successfully');
            })
            .catch((error) => {
    
              console.error('Error added Tags', error);
            });
        }
      };
    const handleKeyDown = (event, categoryId) => {
      if (event.key === "Enter" && event.target.value.trim() !== "") {
        const currentTags = categoryTags[categoryId] || []; 
        const currentTagsCount = currentTags.length;
        const newTag = event.target.value.trim();
  
        if (currentTagsCount !== 8) {
          setCategoryTags((prevTags) => ({
            ...prevTags,
            [categoryId]: [...currentTags, newTag], 
          }));
        } else {
          toast.error("You cannot add more than 8 tags", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
  
        event.target.value = "";
      }
    };
    const navigateToNext2 = () => {
        dispatch(resetProfileDetailing());
        setProfileDetails(3);
    
      };
      
  const backtoPrev4 = () => {
    setProfileDetails(2);
  };
  const changeScreen1 = () => {
    setProfileDetails(3);
  };
  const handleDelete = (categoryId, tagToDelete) => () => {
    setCategoryTags((prevTags) => ({
      ...prevTags,
      [categoryId]: prevTags[categoryId].filter((tag) => tag !== tagToDelete),
    }));
  };
  return (
    
    <>
      <Box
      className="self-stretch h-[11px] relative mix-blend-normal"
      sx={{ width: "100%", marginTop: "5px" }}
    >
      <LinearProgress
        variant="determinate"
        value={33}
        sx={{ height: 10, borderRadius: 5 }}
      />
    </Box>

    <div className="self-stretch flex flex-row flex-wrap items-start justify-start max-w-full [row-gap:20px]">
      <div className="flex-1 flex flex-col items-start justify-start min-w-[491px] max-w-full mq675:min-w-full">
        <h1 className="m-0 self-stretch relative text-inherit leading-[48px] font-bold font-inherit whitespace-nowrap mq450:text-lgi mq450:leading-[29px] mq900:text-7xl mq900:leading-[38px] mq750:text-7xl mq750:leading-[38px]">
          Add Tags
        </h1>
        <div className="self-stretch relative text-sm leading-[22px] whitespace-nowrap">
          Use custom tags to describe yourself and your services so clients can find you easily and get to know you at a glance
        </div>
      </div>
      <Button
        disableElevation={true}
        variant="text"
        sx={{ borderRadius: "0px 0px 0px 0px" }}
        onClick={changeScreen1}
      >
        Skip for now
      </Button>
    </div>

    <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full text-base flex-grow">
      {userCategories.map((category) => (
        <div
          key={category.id}
          className="self-stretch relative leading-[24px] font-semibold"
        >
          {category.displayName}:
          <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-2 box-border gap-[16px] max-w-full text-xs text-text-secondary">
            <div className="self-stretch flex flex-row items-start justify-start max-w-full">
              <TextField
                className="[border:none] bg-white h-[57px] flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
                color="primary"
                label="Enter text and press enter to add a tag"
                helperText="add upto 8 tags"
                fullWidth={true}
                variant="standard"
                onKeyDown={(event) => handleKeyDown(event, category.id)}
                sx={{ "& .MuiInputBase-root": { height: "57px" } }}
              />
              <div className="flex-1 hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border max-w-full">
                <div className="self-stretch relative leading-[18px] flex items-center whitespace-nowrap max-w-full">
                  You can add upto 8 tags.
                </div>
              </div>
            </div>
            <div className="items-start justify-start py-0 pl-0 box-border gap-[16px] min-h-[30px] max-w-[200px]">
              <Stack direction="row" spacing={1}>
                {categoryTags[category.id]?.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    size="small"
                    onDelete={handleDelete(category.id, tag)}
                  />
                ))}
              </Stack>
            </div>
            <div className="self-stretch h-px flex flex-row items-start justify-start pt-px px-0 pb-0 box-border max-w-full">
              <div className="h-px w-0 relative overflow-hidden shrink-0 hidden" />
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className="flex flex-row items-center justify-between gap-[16px] mt-auto">
      <IconButton
        className="h-[50px] w-[50px] shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)]"
        sx={{ borderRadius: "0px 0px 0px 0px" }}
        color="primary"
        onClick={backtoPrev4}
      >
        <Icon>arrow_back_ios_sharp</Icon>
      </IconButton>
      <Button
        className="h-[60px] flex-1 min-w-[670px] max-w-[570px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq675:min-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
        variant="contained"
        onClick={handleNextClick}
        sx={{
          textTransform: "none",
          color: "#fff",
          fontSize: "18px",
          background: "#006a6a",
          border: "#006a6a solid 1px",
          borderRadius: "8px",
          "&:hover": { background: "#006a6a" },
          height: 60,
        }}
      >
         {profileDetailingState.profileDetailing === "isLoading" ? (
          <div className="mt-2">
            <Loader />
          </div>
        ) : profileDetailingState?.profileDetailing?.status === true ? (
          navigateToNext2()
        ) : (
          "Next"
        )}
      </Button>
    </div>
  
    </>
  )
}

export default ProfileDetailTags