import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import Button from '@mui/material/Button'; 
import AppointmentCardPending from '../../SubComponent/AppointmentCardPending';
import AppointmentCardUpcoming from '../../SubComponent/AppointmentCardUpcoming';
import { getPendingBooking, getUpcomingBooking } from '../../../Actions/Practitioner/GetBookingAction';

const Dashboard = ({ practitioner, UpdateProfile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const USER = "user";
  const UserData = JSON.parse(localStorage.getItem(USER));
  const PractitionerId = UserData.user.id;
  const [updateCount, setUpdateCount] = useState(0);

  const upcomingBookings = useSelector((state) => state.getBooking.getUpcomingBooking);
  const pendingBookings = useSelector((state) => state.getBooking.getBooking);
 
  useEffect(() => {
    dispatch(getPendingBooking(PractitionerId));
    dispatch(getUpcomingBooking(PractitionerId));
  }, [PractitionerId, updateCount, dispatch]);

  const handleBookingUpdate = () => {
    setUpdateCount((prev) => prev + 1);
  };

  const sortedPendingBookings = (pendingBookings?.result || [])
    .sort((a, b) => new Date(b.bookingStartTime) - new Date(a.bookingStartTime));

  const filteredPendingBookings = sortedPendingBookings.slice(0, 10);
  const today = new Date();
  const startOfDay = new Date(today.setHours(0, 0, 0, 0)); 
  const endOfDay = new Date(today.setHours(23, 59, 59, 999)); 
  
  const filteredBookings = upcomingBookings?.result?.filter((booking) => {
    const bookingStartDate = new Date(booking.bookingStartTime);
    return bookingStartDate >= startOfDay && bookingStartDate <= endOfDay;
  }) || [];
  
  const handleUpdateProfile = () => {
   
    UpdateProfile();
  
    navigate('/profile');
  };

  return (
    <div className=" flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
      <div>
        <div className="self-stretch flex flex-row items-center justify-start max-w-full mq750:gap-[28px]">
          <div className="flex-1 flex flex-col items-start justify-start py-0 pr-5 pl-0 box-border gap-[8px] max-w-full">
            <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
              Dashboard
            </h1>
            <div className="w-[444px] relative text-sm leading-[22px] inline-block max-w-full">
              Appointments, insights and more
            </div>
            <br>
            </br>
            {(!practitioner.result[0].bussinessDetail ||
            !practitioner.result[0].categoryTags ||
            !practitioner.result[0].userImages)&&  (
              <div className="self-stretch rounded-md bg-m3-sys-light-on-primary box-border flex flex-row flex-wrap items-center justify-start py-[18px] px-[19px] gap-[20px] max-w-full text-lg border-[1px] border-solid border-gainsboro-200">
                <img
                  className="h-5 w-5 relative overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/icons2.svg"
                />
                <div className="flex-1 relative leading-[28px] inline-block min-w-[330px] max-w-full">
                  Complete your profile to reach a wider audience.
                </div>
                <Button
                  className="h-[34px] w-[132px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    fontSize: "14px",
                    background: "#04a3a3", 
                    border: "#04a3a3 solid 1px", 
                    borderRadius: "4px",
                    "&:hover": { background: "#04a3a3" },
                    width: 132,
                    height: 34,
                  }}
                  onClick={handleUpdateProfile}     >
                  Update Profile
                </Button>
              </div>
            )}
          
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full text-lg">
          <div className="self-stretch flex flex-row items-center justify-start">
            <h3 className="mt-10 relative text-inherit leading-[30px] font-inherit mq450:text-base mq450:leading-[24px]">
              New Booking Requests
            </h3>
          </div>
          <div className="self-stretch flex flex-wrap gap-[16px]">
            {pendingBookings && pendingBookings !== "isLoading" && pendingBookings.result && pendingBookings.result?.length > 0 ? (
              filteredPendingBookings?.length > 0 ? (
                filteredPendingBookings && filteredPendingBookings?.map((booking) => (
                  <AppointmentCardPending
                    key={booking.id}
                    booking={booking}
                    onBookingUpdate={handleBookingUpdate}
                  />
                ))
              ) : (
                <div className="text-sm">No Recent bookings </div>
              )
            ) : (
              <div className="text-sm">No Recent booking appointments available</div>
            )}
          </div>
        </div>

        <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full text-lg">
          <div className="self-stretch flex flex-row items-center justify-start">
            <h3 className="mt-10 relative text-inherit leading-[30px] font-inherit mq450:text-base mq450:leading-[24px]">
              Appointments Today
            </h3>
          </div>
          <div className="self-stretch flex flex-wrap gap-[16px]">
            {upcomingBookings && upcomingBookings !== "isLoading" && upcomingBookings.length !== 0 && upcomingBookings.result?.length > 0 ? (
              filteredBookings?.length > 0 ? (
                filteredBookings && filteredBookings?.map((booking) => (
                  <AppointmentCardUpcoming
                    key={booking.id}
                    booking={booking}
                    onBookingUpdate={handleBookingUpdate}
                  />
                ))
              ) : (
                <div className="text-sm">No upcoming appointment today</div>
              )
            ) : (
              <div className="text-sm">No upcoming appointments available</div>
            )}
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
