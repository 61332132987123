import {GET_SCHEDULE_SUCCESS, GET_SCHEDULE_PENDING, GET_SCHEDULE_FAIL, SET_MESSAGE, SET_SCHEDULE, RESET_SCHEDULE } from "../Types"
import PractitionerScheduleService from "../../services/PractitionerScheduleService";
import { toast } from "react-toastify"


export const getSchedules = (body) => async (dispatch) => {
    dispatch({
      type: GET_SCHEDULE_PENDING,
      payload: { getSchedules : "isLoading" },
    });
    return await PractitionerScheduleService.getSchedules(body.id)
      .then(
        (data) => {
          console.log(data);
          if(data.status == 200)
          {
            dispatch({
                type: GET_SCHEDULE_SUCCESS,
                payload: { getSchedules: data.data },
              })
            }
            else
            { dispatch({
                type: GET_SCHEDULE_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: GET_SCHEDULE_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          toast.error(error);
          return Promise.reject();
        }
      )
      .catch((error) => {
        toast.error(error)
        
      });
  };

  export const setSchedule = (selected) => async (dispatch) => {
    dispatch({
      type: SET_SCHEDULE,
      payload: { selection : selected },
    });
  }

  export const resetSchedule = () => async (dispatch) => {
    dispatch({
      type: RESET_SCHEDULE,
    });
  }
