import { SEND_NOTIFICATION_FAIL, SEND_NOTIFICATION_PENDING, SEND_NOTIFICATION_SUCCESS } from "../Actions/Types";

  const initialState = {
    sendNotifications: [],
   
  };
  
  export const notificationReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case SEND_NOTIFICATION_PENDING:
        return {
          ...state,
          sendNotifications:payload.sendNotifications,  // Save the API result
        };
      
      case SEND_NOTIFICATION_SUCCESS:
        return {
          ...state,
         
          sendNotifications: payload.sendNotifications,  
        };
      
      case SEND_NOTIFICATION_FAIL:
        return {
          ...state,
         
        };
      default:
        return state;
    }
  };
  