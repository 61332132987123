import {CANCEL_BOOKING_FAIL, CANCEL_BOOKING_PENDING, CANCEL_BOOKING_SUCCESS, SET_MESSAGE, RESET_CANCEL_BOOKING, REJECT_BOOKING_FAIL, REJECT_BOOKING_PENDING, REJECT_BOOKING_SUCCESS, RESET_REJECT_BOOKING, ACCEPT_BOOKING_FAIL, ACCEPT_BOOKING_PENDING, ACCEPT_BOOKING_SUCCESS, RESET_ACCEPT_BOOKING } from "../Types"
import PractitionerBookingService from "../../services/PractitionerBookingService";
import { toast } from "react-toastify"


export const cancelBooking = (BookingId) => async (dispatch) => {
    dispatch({
      type: CANCEL_BOOKING_PENDING,
      payload: { cancelBooking : "isLoading" },
    });
    return await PractitionerBookingService.cancelBooking(BookingId)
      .then(
        (data) => {
          console.log(data);
          if(data.status == 200)
          {
            dispatch({
                type: CANCEL_BOOKING_SUCCESS,
                payload: { cancelBooking: data.data },
              })
            }
            else
            { dispatch({
                type: CANCEL_BOOKING_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: CANCEL_BOOKING_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };

  export const resetCancelBooking = () => async (dispatch) => {
    dispatch({
      type: RESET_CANCEL_BOOKING,
    });
  }


  export const rejectBooking = (BookingId) => async (dispatch) => {
    dispatch({
      type: REJECT_BOOKING_PENDING,
      payload: { rejectBooking : "isLoading" },
    });
    return await PractitionerBookingService.rejectBooking(BookingId)
      .then(
        (data) => {
          console.log(data);
          if(data.status == 200)
          {
            dispatch({
                type: REJECT_BOOKING_SUCCESS,
                payload: { rejectBooking: data.data },
              })
            }
            else
            { dispatch({
                type: REJECT_BOOKING_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: REJECT_BOOKING_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          toast.error(error);
          return Promise.reject();
        }
      )
      .catch((error) => {
        toast.error(error)
        
      });
  };

  export const resetRejectBooking = () => async (dispatch) => {
    dispatch({
      type: RESET_REJECT_BOOKING,
    });
  }

  export const acceptBooking = (BookingId) => async (dispatch) => {
    dispatch({
      type: ACCEPT_BOOKING_PENDING,
      payload: { acceptBooking : "isLoading" },
    });
    return await PractitionerBookingService.confirmBooking(BookingId)
      .then(
        (data) => {
          console.log(data);
          if(data.status == 200)
          {
            dispatch({
                type: ACCEPT_BOOKING_SUCCESS,
                payload: { acceptBooking: data.data },
              })
            }
            else
            { dispatch({
                type: ACCEPT_BOOKING_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: ACCEPT_BOOKING_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };

  export const resetAcceptBooking = () => async (dispatch) => {
    dispatch({
      type: RESET_ACCEPT_BOOKING,
    });
  }
