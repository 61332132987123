import ConumerService from "../../services/ConumerService";
import { UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_PENDING, UPDATE_PROFILE_FAIL } from "../Types"
import { toast } from "react-toastify"

export const updateProfileConsumer = (Id, FirstName, LastName, DateOfBirth, ProfileImage) => async (dispatch) => {
    dispatch({
        type: UPDATE_PROFILE_PENDING,
        payload: { updateProfileConsumer: "isLoading" },
    });
    return await ConumerService.updateConsumerProfile(Id, FirstName, LastName, DateOfBirth, ProfileImage)
        .then(
            (data) => {
                if (data.data.status == true) {
                    dispatch({
                        type: UPDATE_PROFILE_SUCCESS,
                        payload: { updateProfileConsumer: data.data },
                    });
                    toast.success("Profile Updated Successfully!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else {
                    dispatch({
                        type: UPDATE_PROFILE_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                dispatch({
                    type: UPDATE_PROFILE_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
                toast.error(error);
                return Promise.reject();
              }
            )
            .catch((error) => {
              toast.error(error)
              
            });
};
