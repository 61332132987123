import {PROFILE_DETAILING_SUCCESS, PROFILE_DETAILING_PENDING, PROFILE_DETAILING_FAIL,RESET_PROFILE_DETAILING,RESET_ADD_CATEGORY,  ADD_CATEGORY_PENDING, ADD_CATEGORY_SUCCESS, ADD_CATEGORY_FAIL, ADD_CATEGORY_DETAILING, ADD_BIO_SUCCESS, ADD_BIO_PENDING, ADD_BIO_FAIL, RESET_ADD_BIO} from "../Types"
import ProfileDetailingService from "../../services/ProfileDetailingService";
import { toast } from "react-toastify"

export const addCategory = (categoryId, userId) => async (dispatch) => {
  dispatch({
    type: ADD_CATEGORY_PENDING,
    payload: { addCategory : "isLoading" },
  });
  return await ProfileDetailingService.addCategory(categoryId, userId)
    .then( 
      (data) => {
        console.log(data, "add practitioner category");
        if(data.data.status === true)
        {
          dispatch({
              type: ADD_CATEGORY_SUCCESS,
              payload: { addCategory: data.data },
            })
          }
          else
          { dispatch({
              type: ADD_CATEGORY_FAIL,
            });
            toast.error(data.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        return Promise.resolve();
      },
     
    )
   
};
export const addBio = (body) => async (dispatch) => {
  dispatch({
    type: ADD_BIO_PENDING,
    payload: { addBio : "isLoading" },
  });
  return await ProfileDetailingService.addBio(body)
    .then(
      (data) => {
        console.log(data, "add bio response");
        if(data.data.status === true)
          {
            dispatch({
                type: ADD_BIO_SUCCESS,
                payload: { addBio: data.data },
              })
            }
            else
            { dispatch({
                type: ADD_BIO_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
      },
      (error) => {
        console.log("error ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: ADD_BIO_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        toast.error(error);
          return Promise.reject();
        }
      )
      .catch((error) => {
        toast.error(error)
        
      });
};

export const addPractitionerTag = (body) => async (dispatch) => {
    dispatch({
      type: PROFILE_DETAILING_PENDING,
      payload: { profileDetailing : "isLoading" },
    });
    return await ProfileDetailingService.addPractitionerTag(body)
      .then(
        (data) => {
          console.log(data);
          if(data.status == 200)
          {
            dispatch({
                type: PROFILE_DETAILING_SUCCESS,
                payload: { profileDetailing: data.data },
              })
            }
            else
            { dispatch({
                type: PROFILE_DETAILING_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: PROFILE_DETAILING_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };
  export const addPractitionerBussiness = (body) => async (dispatch) => {
    dispatch({
      type: PROFILE_DETAILING_PENDING,
      payload: { profileDetailing : "isLoading" },
    });
    return await ProfileDetailingService.addPractitionerBussiness(body)
      .then(
        (data) => {
          console.log(data);
          if(data.status == 200)
          {
            dispatch({
                type: PROFILE_DETAILING_SUCCESS,
                payload: { profileDetailing: data.data },
              })
            }
            else
            { dispatch({
                type: PROFILE_DETAILING_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: PROFILE_DETAILING_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };
  export const uploadUserImages = (body) => async (dispatch) => {
    dispatch({
      type: PROFILE_DETAILING_PENDING,
      payload: { profileDetailing : "isLoading" },
    });
    return await ProfileDetailingService.uploadUserImages(body)
      .then(
        (data) => {
          console.log(data);
          if(data.status == 200)
          {
            dispatch({
                type: PROFILE_DETAILING_SUCCESS,
                payload: { profileDetailing: data.data },
              })
            }
            else
            { dispatch({
                type: PROFILE_DETAILING_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: PROFILE_DETAILING_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };

  export const resetCategory = () => ({
    type: RESET_ADD_CATEGORY,
  });
  export const resetBio= () => ({
    type: RESET_ADD_BIO,
  });
  export const resetProfileDetailing = () => ({
    type: RESET_PROFILE_DETAILING,
  });
