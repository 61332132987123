
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetProfileDetailing, uploadUserImages } from '../../Actions/Practitioner/ProfileDetailingAction';
import { Box, Button, Icon, IconButton, LinearProgress } from '@mui/material'
import { toast } from 'react-toastify';
import Loader from './Loader';

const ProfileDetailImages = ({setProfileDetails}) => {
  const USER = "user";
  const user = JSON.parse(localStorage.getItem(USER));
  const dispatch = useDispatch();
    const navigate = useNavigate();
   
  const [logoImage, setLogoImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const profileDetailingState = useSelector((state) => state.profileDetailing);
  const backtoPrev3 = () => {
    setProfileDetails(3);
  };
  const navigateToNext4 = () => {
    dispatch(resetProfileDetailing());
    navigate("/dashboard");
  };
  const changeScreen3 = () => {
    navigate("/dashboard");
  };
  const handleNextClick2 = async () => {
    if (!logoImage) {
      toast.error("Please upload a profile image.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (galleryImages.length === 0) {
      toast.error("Please upload at least one gallery image.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const body = new FormData();
    body.append("userId", user.user.id);
    body.append("profileImage", logoImage);
    galleryImages.forEach((image) => {
      body.append("Images", image);
    });

    dispatch(uploadUserImages(body));
   
  };

  const handleLogoImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 5242880) {
      const fileType = file.type.split("/")[1];
      if (["png", "jpg", "jpeg"].includes(fileType)) {
        setLogoImage(file);
      } else {
        toast.error("Only PNG, JPG, and JPEG file types are allowed.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Maximum file size allowed is 5MB.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleGalleryImageChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedFiles = [];
    files.forEach((file) => {
      if ( file.size <= 5242880) {
        const fileType = file.type.split("/")[1];
        if (["png", "jpg", "jpeg"].includes(fileType)) {
          allowedFiles.push(file);
        } else {
          toast.error("Only PNG, JPG, and JPEG file types are allowed.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("Maximum file size allowed is 5MB.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
    setGalleryImages((prevImages) => [...prevImages, ...allowedFiles]);
  };

  const removeGalleryImage = (index) => {
    setGalleryImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  return (
   <>
         <Box
            className="self-stretch h-[11px] relative mix-blend-normal"
            sx={{ width: "100%", marginTop: "5px" }}
          >
            <LinearProgress
              variant="determinate"
              value={100}
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Box>
          <div className="self-stretch flex flex-col items-start justify-start gap-[32px] max-w-full text-left text-base text-m3-black font-web-secondary-subtitle1 mq675:gap-[16px]">
            <div className="self-stretch flex flex-row items-start justify-between max-w-full [row-gap:20px] gap-[0px] text-13xl mq750:flex-wrap">
              <div className="w-[537px] flex flex-col items-start justify-start max-w-full">
                <h1 className="m-0 self-stretch relative text-inherit leading-[48px] font-bold font-inherit mq450:text-lgi mq450:leading-[29px] mq750:text-7xl mq750:leading-[38px]">
                  Your Identity
                </h1>
                <div className="self-stretch relative text-sm leading-[22px]">
                  Add your logo and images.
                </div>
              </div>
              <div className="rounded overflow-hidden flex flex-row items-center justify-center py-1 px-4 gap-[10px]">
                <div className="bg-hitbox-1000 overflow-hidden hidden flex-col items-center justify-center">
                  <img
                    className="w-3.5 h-3.5 relative overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/wrapper1@2x.png"
                  />
                </div>
                <Button
                  className="flex-1 relative min-w-[83px]"
                  disableElevation={true}
                  variant="text"
                  sx={{ borderRadius: "0px 0px 0px 0px" }}
                  onClick={changeScreen3}
                >
                  Skip for now
                </Button>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full">
              <div className="self-stretch relative leading-[24px] font-semibold">
                Logo
              </div>
              <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-0 pr-[191px] pl-0 box-border gap-[20px] max-w-full text-sm font-poppins mq675:pr-[95px] mq675:box-border mq450:pr-5 mq450:box-border">
                <img
                  className="h-[100px] w-[100px] relative"
                  loading="lazy"
                  alt=""
                  src={
                    logoImage
                      ? URL.createObjectURL(logoImage)
                      : "/component-placeholder-image.svg"
                  }
                />
                <div className="flex-1 flex flex-col items-start justify-start gap-[10px] min-w-[222px] max-w-full">
                  <i className="relative leading-[22px] font-light">
                    Please upload square image, size less than 5MB
                  </i>
                  <input
                    className="self-stretch rounded-8xs bg-gray1-200 flex flex-row items-center justify-start py-2.5 pr-[50px] pl-2.5 mq450:flex-wrap mq450:gap-[15px] mq450:pr-5 mq450:box-border"
                    type="file"
                    accept="image/*"
                    onChange={handleLogoImageChange}
                  />
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
              <div className="self-stretch relative leading-[24px] font-semibold">
                Gallery Images
              </div>
              <div>
                {galleryImages.map((image, index) => (
                  <div key={index} className="relative inline-block">
                    <img
                      className="h-[100px] w-[100px] relative"
                      loading="lazy"
                      alt=""
                      src={URL.createObjectURL(image)}
                    />
                    <IconButton
                      className="absolute top-0 right-0 p-1 bg-white bg-opacity-75"
                      onClick={() => removeGalleryImage(index)}
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </div>
                ))}
              </div>
              <input
                className="self-stretch rounded-4xs overflow-hidden flex flex-col items-center justify-center py-[38px] px-[39px] border-[1px] border-dashed border-lightsteelblue"
                type="file"
                accept="image/*"
                onChange={handleGalleryImageChange}
               
                disabled={galleryImages.length == 3}
              />
            </div>
            <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[32px] max-w-full mq675:gap-[16px]">
              <IconButton
                className="h-[50px] w-[50px] shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)]"
                sx={{ borderRadius: "0px 0px 0px 0px" }}
                color="primary"
                onClick={backtoPrev3}
              >
                <Icon>arrow_back_ios_sharp</Icon>
              </IconButton>
              <Button
                className="h-[60px] flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] min-w-[370px] max-w-full mq675:min-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
                variant="contained"
                onClick={handleNextClick2}
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "18",
                  background: "#006a6a",
                  border: "#006a6a solid 1px",
                  borderRadius: "8px",
                  "&:hover": { background: "#006a6a" },
                  height: 60,
                }}
              >

                {profileDetailingState.profileDetailing === "isLoading" ? (
                  <div className="mt-2">
                    <Loader />
                  </div>
                ) : profileDetailingState?.profileDetailing?.status === true ? (
                  navigateToNext4()
                ) : (
                  "Next"
                )}
              </Button>
            </div>
          </div>
      
   </>
  )
}

export default ProfileDetailImages