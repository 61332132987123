import {GET_SERVICES_SUCCESS,GET_SERVICES_FAIL ,GET_SERVICES_PENDING , SET_MESSAGE} from "../Types"
import ConsumerService from '../../services/ConumerService';




  export const getPopularServices = (pageNumber, pageSize) => async (dispatch) => {
    dispatch({
      type: GET_SERVICES_PENDING,
      payload: { getServices: "isLoading" },
    });
    return await ConsumerService.getPopularServices( pageNumber, pageSize)
      .then(
        (data) => {
          console.log(data);
          if(data.data.status == true)
          {
            dispatch({
                type: GET_SERVICES_SUCCESS,
                payload: { getServices: data.data.result  },
              })
            }
            else
            { dispatch({
                type: GET_SERVICES_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: GET_SERVICES_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          toast.error(error);
          return Promise.reject();
        }
      )
      .catch((error) => {
        toast.error(error)
        
      });

  };