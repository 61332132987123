import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import {
 resetProfileDetailing,
 
} from "../../Actions/Practitioner/ProfileDetailingAction";

import { getAll } from "../../Actions/Catagories/GetCategoriesAction";
import { getPractitioner } from "../../Actions/Consumer/GetPractitionerAction";
import ProfileDetailBio from "../SubComponent/ProfileDetailBio";
import ProfileDetailCategories from "../SubComponent/ProfileDetailCategories";
import ProfileDetailAddress from "../SubComponent/ProfileDetailAddress";
import ProfileDetailTags from "../SubComponent/ProfileDetailTags";
import ProfileDetailImages from "../SubComponent/ProfileDetailImages";

const IntroDetails = () => {
  const user = JSON.parse(localStorage.getItem("user"));
 
 const dispatch = useDispatch();
 useEffect(()=>{
  const id = user?.user?.id
  dispatch(resetProfileDetailing());
  if(id){
    dispatch(getPractitioner(id))
  }
 },[dispatch]);
  useEffect(() => {
  
    dispatch(getAll());
  }, []);
 
  const [profileDetail, setProfileDetail] = useState(1);
 
  const renderSections = () => {
    switch (profileDetail) {
      case 1:
        return <ProfileDetailCategories setProfileDetails={setProfileDetail} />;
      case 2:
        return <ProfileDetailBio setProfileDetails={setProfileDetail} />;
        case 3:
        return <ProfileDetailAddress setProfileDetails={setProfileDetail} />;
        case 4:
        return <ProfileDetailTags setProfileDetails={setProfileDetail} />;
        case 5:
        return <ProfileDetailImages setProfileDetails={setProfileDetail}  />;
       
      default:
        return null;
    }
  };

  return (
    <div className="w-[850px] rounded-2xl shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] max-w-full flex bg-white flex-row items-start justify-start ml-6 box-border leading-[normal] tracking-[normal] mq675:gap-[19px]">
      
        <section className="w-[600px] h-[600px] flex-1 rounded-2xl bg-white box-border flex flex-col items-start justify-between p-6 gap-[24px] text-left text-13xl text-m3-black font-web-secondary-caption mq675:pt-5 mq675:pb-5 mq900:pt-5 mq900:pb-5">
       
           {renderSections()}
        </section>
     
    </div>
  );
};

export default IntroDetails;