import { Box, Button, LinearProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, resetCategory } from '../../Actions/Practitioner/ProfileDetailingAction';
import { getPractitioner } from '../../Actions/Consumer/GetPractitionerAction';
import { toast } from 'react-toastify';

const ProfileDetailCategories = ({setProfileDetails}) => {
    const USER = "user";
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem(USER));
    const CategoryState = useSelector((state) => state.addCategory.addCategory);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryLocked, setCategoryLocked] = useState(false);
    
    const allCategories = useSelector((state) => Array.isArray(state.getCategories.getAll) ? state.getCategories.getAll : []);
    const practitionerData = useSelector((state) => state.practitioner.practitioner)
   
    const navigateToNext1 = () => {
      setProfileDetails(2);
      const id = user.user.id;
      dispatch(resetCategory());
      dispatch(getPractitioner(id));
    };
    useEffect(() => {
      CategoryState && CategoryState.length !== 0 && CategoryState.status && CategoryState.status === true && navigateToNext1();
   
    }, [CategoryState, ]);
    
    useEffect(() => {
        const savedCategory = localStorage.getItem("selectedCategory") || 
          (practitionerData?.result?.length > 0 && practitionerData.result[0]?.categories?.length > 0 
            ? practitionerData.result[0].categories[0].id 
            : null);
        
        console.log('Selected Category:', savedCategory);
        
        if (savedCategory) {
          setSelectedCategory(savedCategory);
          setCategoryLocked(true);
        }
      }, [practitionerData]); 
   
    const handleNextClick3 = () => {
        if (!selectedCategory) {
          toast.error("Please select a category", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
    
        const categoryId = selectedCategory;
        const userId = user?.user?.id;
        if (!categoryLocked) {
       
          localStorage.setItem('selectedCategory', categoryId);
    
          dispatch(addCategory(categoryId, userId))
            .then(() => {
              console.log('Category added successfully');
            })
            .catch((error) => {
              console.error('Error adding category', error);
            });
        }
        navigateToNext1();
      }
    
    const handleSelect = (categoryId) => {
        if (!categoryLocked) {
          setSelectedCategory(categoryId);
        }
      };
    
     
      const getRows = (categories) => {
    
        const descriptions = [
          'Trauma, Pediatric, Psychoanalysis, DBT, Psychodynamic',
          'Life Counseling, Meditation, Hypnotherapy, Spiritual Help and Guidance',
          'Acupuncture & Acupressure, Massage,  Trauma release exercises,Yoga',
          'Naturopath, Traditional Chinese Medicine, Ayurvedic, Homeopathy'
        ];
    
    
        const enhancedCategories = categories.map((category, index) => {
          const descriptionIndex = Math.floor(index / (categories.length / descriptions.length));
          return {
            ...category,
            description: descriptions[descriptionIndex] || ''
          };
        });
    
       
        const rows = [];
        for (let i = 0; i < enhancedCategories.length; i += 2) {
          rows.push(enhancedCategories.slice(i, i + 2));
        }
        return rows;
      };
    
      const rows = getRows(allCategories);
  return (
   <>
      <Box className="self-stretch h-[11px] relative mix-blend-normal" sx={{ width: "100%", marginTop: "5px" }}>
            <LinearProgress variant="determinate" value={12} sx={{ height: 10, borderRadius: 5 }} />
          </Box>

          <div className="self-stretch flex flex-col items-start justify-start max-w-full text-13xl">
            <h1 className="m-0 self-stretch text-inherit text-2xl font-bold leading-[36px]">
              What is your primary healing modality?
            </h1>
            <p className="mt-1 text-sm text-gray-600">
              Identify the main approach you use for healing: What's your go-to method?
            </p>
          </div>

          <div className="self-stretch flex flex-col items-center gap-[16px] mx-auto">
            {rows.length === 0 ? (
              <p>No categories available.</p>
            ) : (
              rows.map((row, rowIndex) => (
                <div className="flex flex-row gap-[16px]" key={rowIndex}>
                  {row.map((category) => (
                    <div
                    key={category.id}
                    className={`relative flex flex-col justify-center items-start bg-[#F4F1FC] border-[3px] rounded-[8px] h-[116px] w-[314px] p-3 box-border ${
                      categoryLocked ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                    }`}
                    style={{
                      border: `1px solid ${selectedCategory === category.id ? '#6366F1' : 'transparent'}`,
                    }}
                    onClick={() => handleSelect(category.id)}
                  >
                      <div className="flex flex-col justify-center h-full w-full text-left">
                        <h1 className="text-[16px] font-bold m-0">{category.name}</h1>
                        <p className="text-[14px] text-gray-800 m-0 mt-[4px]">{category.description}</p>
                      </div>
                      {selectedCategory === category.id && (
                        <img
                          src="/vector.png"
                          alt="Selected"
                          className="absolute right-4 top-1/2 transform -translate-y-1/2 w-[18px] h-[12px]"
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))
            )}
          </div>


          <div className="self-stretch flex flex-row items-center justify-end gap-[32px] max-w-full mt-6">
            <Button
              className="h-[60px] flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] min-w-[370px] max-w-full"
              variant="contained"
              onClick={handleNextClick3}
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "18px",
                background: "#006a6a",
                border: "#006a6a solid 1px",
                borderRadius: "8px",
                "&:hover": { background: "#006a6a" },
                height: 60,
              }}
            >
              {CategoryState === "isLoading" ? (
                <div className="mt-2">
                  <Loader />
                </div>
              ) : (
                "Next"
              )}
            </Button>
          </div>

       
   </>
  )
}

export default ProfileDetailCategories