import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
} from "@mui/material";

import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/SubComponent/Loader";
import { confirmForgotPassword } from "../Actions/Auth/ConfirmForgotPasswordAction";
import { forgotPassword } from "../Actions/Auth/ForgotPasswordAction";

const PractitionerResetPassword = () => {
  const forgotPasswordState = useSelector((state) => state.forgotPassword);
  const confirmForgotPasswordState = useSelector(
    (state) => state.confirmForgotPassword
  );
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const dispatch = useDispatch();

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const email = forgotPasswordState.UserData;
  const navigate = useNavigate();

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    if (!validatePassword(newPassword)) {
      setPasswordError(
        "Password must include: length greater than 8 characters, at least 1 uppercase, 1 numeric value and 1 special character"
      );
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const newPassword = event.target.value;
    setConfirmPassword(newPassword);
    if (newPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleSubmit = async () => {
    const body = {
      email: email,
      verificationCode: otp,
      password: password,
      userType: 1,
    };
    let call = true;
    if (!validatePassword(password)) {
      call = false;
      setPasswordError(
        "Password must include: length greater than 8 characters, at least 1 uppercase, 1 numeric value and 1 special character"
      );
      return;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      call = false;
      return;
    }
    if (call == true) {
      dispatch(confirmForgotPassword(body));
    }
  };
  const handleBack = () => {
    navigate("/practitioner-sign-in");
  };
  const handleResendClick = async () => {
    const type = '1';
    dispatch(forgotPassword(email,type));
  };

  const navigateToNext = () => {
    navigate("/practitioner-sign-in");
  };
  return (
    <div className="w-full relative bg-m3-sys-light-on-primary overflow-hidden flex flex-row items-start justify-start py-10 pr-10 pl-[140px] box-border gap-[47px] tracking-[normal] leading-[normal] mq750:gap-[23px] mq750:pl-[70px] mq750:box-border mq450:pl-5 mq450:box-border mq1125:flex-wrap">
      <div className="h-2 w-2 relative overflow-hidden shrink-0 hidden" />
      <div className="w-[480px] flex flex-col items-start justify-start pt-8 px-0 pb-0 box-border min-w-[480px] max-w-full mq750:min-w-full mq1125:flex-1">
        <div className="self-stretch flex flex-col items-start justify-start gap-[80px] max-w-full mq750:gap-[40px]">
          <div className="w-[302px] flex flex-row items-end justify-start gap-[15.9px]">
          <img
    className="relative overflow-hidden"
    loading="lazy"
    alt="Logo"
    src="/PractLogo.png" 
    style={{ width: '236px', height: '47px' }} 
  />
          </div>
          <div className="self-stretch flex flex-col items-end justify-start gap-[32.3px] max-w-full mq450:gap-[16px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-xl text-neutral-10">
              <h2 className="m-0 relative text-inherit leading-[30px] font-bold font-inherit mq450:text-base mq450:leading-[24px]">
                Forgot Password?
              </h2>
              <div className="self-stretch relative text-base leading-[24px]">
                <span>{`Enter 6 digit OTP sent to `}</span>
                <b>{email + " "}</b>
                <span>update your password.</span>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px] text-text-secondary mq450:flex-wrap">
              <div className="w-10 flex flex-row items-start justify-start">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  separator={<span>&nbsp;</span>}
                  isInputNum
                  inputStyle={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    fontSize: "18px",
                    textAlign: "center",
                    outline: "none",
                  }}
                  containerStyle={{ display: "flex", gap: "20px" }}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start max-w-full font-h5-medium">
              <TextField
                className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[240px] max-w-full"
                color="primary"
                label="New Password"
                required={true}
                fullWidth={true}
                variant="standard"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                error={Boolean(passwordError)}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowPasswordClick}
                        aria-label="toggle password visibility"
                      >
                        <Icon>
                          {showPassword ? "visibility_off" : "visibility"}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ "& .MuiInputBase-root": { height: "53px" } }}
              />
              <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                  Helper text
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start max-w-full font-h5-medium mt-4">
              <TextField
                className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[240px] max-w-full mq450:h-auto"
                color="primary"
                label="Confirm Password"
                required={true}
                fullWidth={true}
                variant="standard"
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={Boolean(confirmPasswordError)}
                helperText={confirmPasswordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowPasswordClick}
                        aria-label="toggle password visibility"
                      >
                        <Icon>
                          {showPassword ? "visibility_off" : "visibility"}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ "& .MuiInputBase-root": { height: "53px" } }}
              />
              <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                  Helper text
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end text-center">
              <div className="flex flex-row items-start justify-start gap-[2px]">
                <div className=" relative leading-[18px] mt-2">
                  Didn’t receive the code?
                </div>
                <Button
                  className="[text-decoration:underline] relative leading-[18px] text-mediumblue inline-block min-w-[21px]"
                  onClick={handleResendClick}
                >
                  Resend
                </Button>
              </div>
            </div>
            <Button
              className="self-stretch h-[60px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq450:pl-5 mq450:pr-5 mq450:box-border mt-4"
              variant="contained"
              disabled={!(otp.length == 6)}
              onClick={handleSubmit}
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "18",
                background: "#006a6a",
                borderRadius: "4px",
                "&:hover": { background: "#006a6a" },
              }}
            >
              {confirmForgotPasswordState.confirmForgotPassword ===
              "isLoading" ? (
                <div className="mt-2">
                  <Loader />
                </div>
              ) : confirmForgotPasswordState.confirmForgotPassword.status &&
                confirmForgotPasswordState.confirmForgotPassword.status ==
                  true ? (
                navigateToNext()
              ) : (
                "Update Password"
              )}
            </Button>
            <div className="self-stretch h-[42px] flex flex-row items-start justify-start mt-4">
              <Button
                className="self-stretch w-[152px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
                startIcon={
                  <img width="16px" height="16px" src="/navigate-before.svg" />
                }
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#434343",
                  fontSize: "14",
                  background: "#fff",
                  border: "#d9d9d9 solid 1px",
                  borderRadius: "4px",
                  "&:hover": { background: "#fff" },
                  width: 152,
                }}
                onClick={handleBack}
              >
                Back to Login
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-row items-start justify-start min-w-[476px] max-w-full mq750:min-w-full">
        <div className="h-[944px] flex-1 relative max-w-full">
          <img
            className="absolute h-full top-[0px] bottom-[0px] left-[93px] rounded-3xl max-h-full w-[640px] object-cover"
            loading="lazy"
            alt=""
            src="/chrislee70l1tdai6rmunsplash-2@2x.png"
          />
          <div className="absolute top-[634px] left-[0px] bg-opacity-35 [backdrop-filter:blur(22.2px)] rounded-[50%] bg-teal-300 box-border w-[187px] h-[187px] z-[1] border-[0px] border-solid border-teal-200" />
        </div>
      </div>
    </div>
  );
};

export default PractitionerResetPassword;
