import {ADD_SERVICE_FAIL, ADD_SERVICE_PENDING, RESET_ADD_SERVICE, ADD_SERVICE_SUCCESS, SELECTION_SUCCESS,SERVICE_DATA, OTHER_USER_DETAIL_SUCCESS } from "../Types"
import addServiceService from "../../services/addServiceService";
import { toast } from "react-toastify"


export const addService = (body) => async (dispatch) => {
    dispatch({
      type: ADD_SERVICE_PENDING,
      payload: { addService : "isLoading" },
    });
    return await addServiceService.addService(body)
      .then(
        (data) => {
          console.log(data);
          if(data.status == 200)
          {
            dispatch({
                type: ADD_SERVICE_SUCCESS,
                payload: { addService: data.data },
              })
            }
            else
            { dispatch({
                type: ADD_SERVICE_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: ADD_SERVICE_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          toast.error(error);
          return Promise.reject();
        }
      )
      .catch((error) => {
        toast.error(error)
        
      });
  };

  export const resetAddService = () => ({
    type: RESET_ADD_SERVICE,
  });

  export const setSelection = (num) => ({
        type: SELECTION_SUCCESS,
        payload: { selection: num },
  });
  export const setOtherUserDetail = (consumerDetail) => ({
    type: OTHER_USER_DETAIL_SUCCESS,
    payload: { otherUserDetail: consumerDetail },
});
  export const setServiceData = (data) => ({
    type: SERVICE_DATA,
    payload: { srvData:  data},
});