import ConumerService from "../../services/ConumerService";
import {CANCEL_BOOKING_FAIL, CANCEL_BOOKING_PENDING, CANCEL_BOOKING_SUCCESS, SET_MESSAGE, RESET_CANCEL_BOOKING } from "../Types"
import { toast } from "react-toastify"

export const cancelBookingConsumer = (BookingId) => async (dispatch) => {
    dispatch({
      type: CANCEL_BOOKING_PENDING,
      payload: { cancelBooking : "isLoading" },
    });
    return await ConumerService.cancelBookingConsumer(BookingId)
      .then(
        (data) => {
          console.log(data);
          if(data.status == 200)
          {
            dispatch({
                type: CANCEL_BOOKING_SUCCESS,
                payload: { cancelBooking: data.data },
              })
            }
            else
            { dispatch({
                type: CANCEL_BOOKING_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: CANCEL_BOOKING_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          toast.error(error);
          return Promise.reject();
        }
      )
      .catch((error) => {
        toast.error(error)
        
      });
  };

  export const resetCancelBookingConsumer = () => async (dispatch) => {
    dispatch({
      type: RESET_CANCEL_BOOKING,
    });
  }