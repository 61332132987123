import { Box, Button, Icon, IconButton, LinearProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { addBio, resetBio } from '../../Actions/Practitioner/ProfileDetailingAction';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';

const ProfileDetailBio = ({setProfileDetails}) => {
  const USER = "user";
     const navigate = useNavigate();
     const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem(USER));
    const BioState = useSelector((state) => state.addBio.addBio);
    const [professionalTitle, setProfessionalTitle] = useState("");
    useEffect(() => {
  
         const savedTitle = localStorage.getItem('professionalTitle');
         if (savedTitle) {
           setProfessionalTitle(savedTitle);
         }
       
      }, []);
    useEffect(() => {
        BioState && BioState.length !== 0 && BioState.status && BioState.status === true && navigateToNext5();
     
       }, [ BioState, navigate]);
    const backtoPrev1 = () => {
        setProfileDetails(1);
      };
      const changeScreen4 = () => {
        setProfileDetails(4);
      };
      const navigateToNext5 = () => {
        setProfileDetails(4);
        dispatch(resetBio());
      };
    const handleTitleChange = (event) => {
      const title = event.target.value;
      setProfessionalTitle(title);
     
      localStorage.setItem('professionalTitle', title);
    };
    const handleNextClick4 = () => {
        if (!professionalTitle) {
          toast.error("Please add a title", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
        const body = {
          userId: user.user.id,
          bio: professionalTitle
        };
        
        dispatch(addBio(body))
          .then(() => {
    
            console.log('Bio added successfully');
          })
          .catch((error) => {
    
            console.error('Error added Bio', error);
          });
      }
  
  return (
   <>
     <Box className="self-stretch h-[11px] relative mix-blend-normal" sx={{ width: "100%", marginTop: "5px" }}>
      <LinearProgress variant="determinate" value={12} sx={{ height: 10, borderRadius: 5 }} />
    </Box>

    <div className="self-stretch flex flex-row flex-wrap items-start justify-start max-w-full [row-gap:20px]">
      <div className="flex-1 flex flex-col items-start justify-start min-w-[491px] max-w-full mq675:min-w-full">
        <h1 className="m-0 self-stretch text-inherit leading-[48px] font-bold font-inherit whitespace-nowrap mq450:text-lgi mq450:leading-[29px] mq900:text-7xl mq900:leading-[38px] mq750:text-7xl mq750:leading-[38px]">
          How do you refer to yourself professionally?
        </h1>
        <div className="self-stretch text-sm leading-[22px] whitespace-nowrap">
          Define your professional identity: What title or role best represents you?
        </div>
      </div>
      <Button
        disableElevation={true}
        variant="text"
        sx={{ borderRadius: "0px 0px 0px 0px" }}
        onClick={changeScreen4}
      >
        Skip for now
      </Button>
    </div>

    <div className="self-stretch flex flex-col gap-[24px] mt-7"> {/* Increased gap here */}
      <div className="flex items-center w-full">
        <label
          className="text-lg font-semibold flex items-center justify-center border border-black"
          style={{
            height: "57px",
            backgroundColor: "#FAFAFA",
            padding: "0 16px",
            border: '1px solid #D9D9D9',
            borderRadius: "4px 0 0 4px",
            width: "100px", 
          }}
        >
          I am a
        </label>
        <TextField
          className="flex-1 bg-[#FAFAFA]"
          variant="outlined"
          value={professionalTitle}
          onChange={handleTitleChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: 57,
              borderRadius: "0 4px 4px 0",
              border: "1px solid #D9D9D9",
              display: "flex",
              alignItems: "center",
              borderLeft: 'none'
            },
            "& .MuiOutlinedInput-input": {
              padding: "0 16px",
            },
            maxWidth: "calc(100% - 100px - 16px)" 
          }}
        />
      </div>
    </div>

    <div className="flex flex-row items-center justify-between gap-[16px] mt-auto">
      <IconButton
        className="h-[50px] w-[50px] shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)]"
        sx={{ borderRadius: "0px 0px 0px 0px" }}
        color="primary"
        onClick={backtoPrev1}
      >
        <Icon>arrow_back_ios_sharp</Icon>
      </IconButton>
      <Button
        className="h-[60px] flex-1 min-w-[670px] max-w-[570px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq675:min-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
        variant="contained"
        onClick={handleNextClick4}
        sx={{
          textTransform: "none",
          color: "#fff",
          fontSize: "18px",
          background: "#006a6a",
          border: "#006a6a solid 1px",
          borderRadius: "8px",
          "&:hover": { background: "#006a6a" },
          height: 60,
        }}
      >
        {BioState === "isLoading" ? (
          <div className="mt-2">
            <Loader />
          </div>
        ) : (
          "Next"
        )}
      </Button>
    </div>
   </>
  )
}

export default ProfileDetailBio