
import DeleteImageService from "../../services/DeleteImageService";
import { DELETE_IMAGE_SUCCESS, DELETE_IMAGE_PENDING, SET_MESSAGE, DELETE_IMAGE_FAIL } from "../Types";

import { toast } from "react-toastify";

export const deleteImage = (userId, imageUrl) => async (dispatch) => {
    dispatch({
        type: DELETE_IMAGE_PENDING,
        payload: { deleteImage: "isLoading" },
    });

    return await DeleteImageService.deleteImage(userId, imageUrl)
        .then(
            (data) => {
                if (data.data.status === true) {
                    dispatch({
                        type: DELETE_IMAGE_SUCCESS,
                        payload: { imageUrl: imageUrl },
                    });
                } else {
                    dispatch({
                        type: DELETE_IMAGE_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                dispatch({
                    type: DELETE_IMAGE_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
                toast.error(error);
          return Promise.reject();
        }
      )
      .catch((error) => {
        toast.error(error)
        
      });
};

// Action to reset the delete state
export const resetDeleteImage = () => ({
    type: RESET_DELETE_IMAGE,
});
