import React, { useEffect } from "react";
import { Routes, Route, useNavigationType, useLocation } from "react-router-dom";
import FirstLandingPage from "./pages/FirstLandingPage";
import ConsumerLandingPage from "./pages/ConsumerLandingPage";
import PractitionerLandingPage from "./pages/PractitionerLandingPage";
import ConsumerPreferences from "./pages/ConsumerPreferences";
import ConsumerPreferences1 from "./pages/ConsumerPreferences1";
import PractitionerDetails from "./pages/PractitionerDetails";
import ServiceDetails from "./pages/ServiceDetails";
import SearchResultsPractitioners from "./pages/SearchResultsPractitioners";
import SearchResultsServices from "./pages/SearchResultsServices";
import ConsumerSignIn from "./pages/ConsumerSignIn";
import ConsumerForgotPassword from "./pages/ConsumerForgotPassword";
import ConsumerResetPassword from "./pages/ConsumerResetPassword";
import ConsumerSignUp from "./pages/ConsumerSignUp";
import ConsumerSignUpEmailVer from "./pages/ConsumerSignUpEmailVer";
import PractitionerSignUp from "./pages/PractitionerSignup";
import PractitionerSignIn from "./pages/PractitionerSignIn";
import PractitionerSignUpVerif from "./pages/PractitionerSignUpEmailVer";
import PractitionerForgotPassword from "./pages/PractitionerForgotPassword";
import PractitionerResetPassword from "./pages/PractitionerResetPassword"
import PractitionerDashboard from "./pages/PractitionerDashboard";
import PractitionerProfileDetailing from "./pages/PractitionerProfileDetailing";
import ConsumerProfile from "./pages/ConsumerProfile";
import AccessDenied from "./pages/AccessDenied";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginProtected from './routes/LoginProtected';
import Dashboard from "./components/Layout/Dashboard/Dashboard";
import Services from "./components/Layout/Dashboard/Services";
import Appointment from "./components/Layout/Dashboard/Appointment";
import InboxComponent from "./components/Layout/Dashboard/Inbox";
import Profile from "./components/Layout/Dashboard/Profile";
import Schedule from "./components/Layout/Dashboard/Schedule";
import Inbox from "./components/Layout/Inbox";
import ConsumerSchedule from "./components/Layout/ConsumerSchedule";
import PrivacyPolicy from "./pages/Policy";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/consumer-landing-page":
        title = "";
        metaDescription = "";
        break;
      case "/practitioner-landing-page":
        title = "";
        metaDescription = "";
        break;
        case "/policy":
        title = "";
        metaDescription = "";
        break;
      case "/consumer-preferences-01":
        title = "";
        metaDescription = "";
        break;
      case "/consumer-preferences-02":
        title = "";
        metaDescription = "";
        break;
      case "/practitioner-details":
        title = "";
        metaDescription = "";
        break;
      case "/service-details":
        title = "";
        metaDescription = "";
        break;
      case "/search-results-practitioners":
        title = "";
        metaDescription = "";
        break;
      case "/search-results-services":
        title = "";
        metaDescription = "";
        break;
      case "/consumer-sign-in":
        title = "";
        metaDescription = "";
        break;
      case "/consumer-forgot-password":
        title = "";
        metaDescription = "";
        break;
      case "/services":
        title = "";
        metaDescription = "";
        break;
        case "/schedule":
        title = "";
        metaDescription = "";
        break;
        case "/appointment":
        title = "";
        metaDescription = "";
        break;
        case "/profile":
        title = "";
        metaDescription = "";
        break;
        case "/inbox":
        title = "";
        metaDescription = "";
        break;
        case "/dashboard":
        title = "";
        metaDescription = "";
        break;
        
      case "/practitioner-forgot-password":
        title = "";
        metaDescription = "";
      break;
      case "/consumer-reset-password":
        title = "";
        metaDescription = "";
        break;
      case "/practitioner-reset-password":
        title = "";
        metaDescription = "";
        break;  
      case "/consumer-sign-up":
        title = "";
        metaDescription = "";
        break;
      case "/consumer-sign-up-email-verification":
        title = "";
        metaDescription = "";
        break;
      case "/practitioner-sign-up":
        title = "";
        metaDescription = "";
      break;
      case "/practitioner-sign-in":
          title = "";
          metaDescription = "";
      break;
      case "/practitioner-sign-up-email-verification":
        title = "";
        metaDescription = "";
        break;
        case "/practitioner-profile-detailing":
          title = "";
          metaDescription = "";
          break;
          
          case "/consumer-profile":
          title = "";
          metaDescription = "";
          break;
          case "/consumer-schedule":
            title = "";
            metaDescription = "";
            break;
            case "/consumer-inbox":
              title = "";
              metaDescription = "";
              break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.addEventListener('message', (event) => {
  //     if (event.data && event.data.type === 'notification_received') {
  //       const payload = event.data.payload;
  //       // handleNotificationReceived(payload);
  //     }
  //   });
  // }

  return (
      <div> 
       <Routes>
        <Route path="/" element={<FirstLandingPage />} />
        <Route path="/consumer-landing-page" element={<ConsumerLandingPage />} />
        <Route path="/practitioner-landing-page" element={<PractitionerLandingPage />} />

        <Route path="/practitioner-details" element={<PractitionerDetails />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        <Route path="/search-results-practitioners" element={<SearchResultsPractitioners />} />
        <Route path="/search-results-services" element={<SearchResultsServices />} />
        <Route path="/consumer-sign-in" element={<ConsumerSignIn />} />
        <Route path="/consumer-forgot-password" element={<ConsumerForgotPassword />} />
        <Route path="/consumer-reset-password" element={<ConsumerResetPassword />} />
        <Route path="/consumer-sign-up" element={<ConsumerSignUp />} />
        <Route path="/practitioner-sign-up" element={<PractitionerSignUp />} />
        <Route path="/practitioner-sign-in" element={<PractitionerSignIn />} />
        <Route path="/consumer-sign-up-email-verification" element={<ConsumerSignUpEmailVer />} />
        <Route path="/practitioner-sign-up-email-verification" element={<PractitionerSignUpVerif />} />
        <Route path="/practitioner-forgot-password" element={<PractitionerForgotPassword />} />
        <Route path="/practitioner-reset-password" element={<PractitionerResetPassword />} />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="/practitioner-profile-detailing" element={<PractitionerProfileDetailing />} />
        <Route path="/consumer-preferences-01" element={<ConsumerPreferences />} />
        <Route path="/consumer-preferences-02" element={<ConsumerPreferences1 />} />
        <Route path="/policy" element={<PrivacyPolicy/>} />
        {/* Protected Routes */}
        <Route element={<LoginProtected />}>
    
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/services" element={<Services/>} />
          <Route path="/appointments" element={<Appointment/>} />
          <Route path="/inbox" element={<InboxComponent/>} />
          <Route path="/profile" element={<Profile/>} />
          <Route path="/schedule" element={<Schedule/>} />
          <Route path="/consumer-profile" element={<Profile />} />
          <Route path="/consumer-inbox" element={<Inbox />} />
          <Route path="/consumer-schedule" element={<ConsumerSchedule />} />
         
        </Route>
      </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
  );
}

export default App;