import { GOOGLE_LOGIN_FAIL, GOOGLE_LOGIN_PENDING, GOOGLE_LOGIN_SUCCESS, RESET_GOOGLE_LOGIN_STATE, SET_MESSAGE } from "../../Actions/Types";


  import GoggleLoginService from "../../services/GoogleLogin-service";
  import { toast } from "react-toastify";
  
  export const googleLoginAction = (body) => async (dispatch) => {
    dispatch({
      type:GOOGLE_LOGIN_PENDING,
      payload: { googleLogin: "isLoading" },
    });
    return await GoggleLoginService.googleLogin(body)
      .then(
        (data) => {
          console.log(data,'goggle data')
          if (data.data.status == true) {
            dispatch({
              type:GOOGLE_LOGIN_SUCCESS,
              payload: { googleLogin: data.data, UserData: body },
            });
          
            localStorage.setItem("user",JSON.stringify(data.data.result[0]));
          
          } else {
            dispatch({
              type:GOOGLE_LOGIN_FAIL,
              payload: { googleLogin: data.data, UserData: body },
            });
            if (data.data.message != "User is not confirmed.") {
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (data.data.message == "User is not confirmed.") {
              toast.info("User confirmation required", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type:GOOGLE_LOGIN_FAIL,
            payload: { googleLogin: error.response.data, UserData: body },
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          toast.error(error);
          return Promise.reject();
        }
      )
      .catch((error) => {
        toast.error(error);
        
      });
  };
  
  export const resetGoogleLoginState = () => ({
    type: RESET_GOOGLE_LOGIN_STATE,
  });
