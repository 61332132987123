import PractitionerMainHeader from "../components/Layout/PractitionerMainHeader";
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/SubComponent/Loader";
import { getPractitioner } from "../Actions/Consumer/GetPractitionerAction";
import ServiceForm from "../components/Layout/ServiceForm";
import AddServiceForm from "../components/Layout/AddServiceForm";
import { useNavigate } from "react-router-dom";
import { setSelection } from "../Actions/Practitioner/addServiceAction";

import AddSchedule from "../components/Layout/AddSchedule";
import { getSchedules } from "../Actions/Practitioner/getSchedulesAction"

import EditSchedule from "../components/Layout/EditSchedule";

import { getToken, getMassaging } from "firebase/messaging";
import { messaging } from "../firebase";
import userService, * as UserService from "../services/user.service";

import PractitionerSidebar from "../components/Layout/PractitionerSidebar";
import Dashboard from "../components/Layout/Dashboard/Dashboard";
import Services from "../components/Layout/Dashboard/Services";
import Appointment from "../components/Layout/Dashboard/Appointment";
import InboxComponent from "../components/Layout/Dashboard/Inbox";
import Profile from "../components/Layout/Dashboard/Profile";
import Schedule from "../components/Layout/Dashboard/Schedule";

const PractitionerDashboard = () => {
  const [otherUserDetail, setOtherUserDetail] = useState(null);
  const [updateCount, setUpdateCount] = useState(0);
 
  const UserData = JSON.parse(localStorage.getItem("user"));
  const id = UserData.user.id;
  const PractitionerId = UserData.user.id;
  const USER = "user";
  const navigate = useNavigate();
  const practitioner = useSelector((state) => state.practitioner.practitioner);
  const selection = useSelector((state) => state.addService.selection);
  const srvData = useSelector((state) => state.addService.srvData);
  const [fcmPosted, setFcmPosted] = useState(localStorage.getItem('fcmPosted') === 'true');
  
  const [fcm, setFcm] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const user = JSON.parse(localStorage.getItem(USER));
  const employeeId = user && user.user !== undefined && user.user != null ? user.user.id : false;
  
  console.log(deviceId,'device');

 
  useEffect(() => {
    if (!fcmPosted) {
      requestFCMPermission();
    }
  }, [fcmPosted]);

  useEffect(() => {
    postFCMToken();
  }, [deviceId, fcm]);

  async function requestFCMPermission() {
    try {
      // Geting FCM token and device id
      requestPermission();
      getDeviceID();

      // Saving token and device ID to fcmBody
      const fcmBody = {
        id: employeeId,
        fcmId: fcm,
        deviceId: deviceId,
      };
      await userService.addFcmToken(fcmBody);
    } catch (error) {
      console.error("Error requesting FCM permission:", error);
    }
  }

  async function postFCMToken() {
    if (fcm !== "" && deviceId !== "" && employeeId !== false) {
      const fcmBody = {
        id: employeeId,
        fcmId: fcm,
        deviceId: deviceId,
      };
      localStorage.setItem('deviceId', JSON.stringify(deviceId));

      await userService.addFcmToken(fcmBody);
      setFcm("");
      setDeviceId("");
      setFcmPosted(true);
      localStorage.setItem('fcmPosted', true);
    }
  }
  function getDeviceID() {
    if (navigator && navigator.userAgent) {
      setDeviceId(navigator.userAgent);
      return navigator.userAgent;
    } else {
      setDeviceId("default_device_id");
      return "default_device_id";
    }
  }

  async function requestPermission() {
    const currentPermission = Notification.permission;

    if (currentPermission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BFOXm1XAOLFvgw7dHWEn8Hj8ZRiru-Y0g44AqRBtQ1fuWfLG6lCVJJUQ4oNeL3jQRXA1I4OZM6AR2h9B8yXQQmk",
      });

      setFcm(token);
      return token;
    } else if (currentPermission === "denied") {

      return null; // Permission denied
    }

    // If permission hasn't been granted or denied, proceed with the request
    const requestedPermission = await Notification.requestPermission();

    if (requestedPermission === "granted") {
      try {
        const token = await getToken(messaging, {
          vapidKey:
            "BFOXm1XAOLFvgw7dHWEn8Hj8ZRiru-Y0g44AqRBtQ1fuWfLG6lCVJJUQ4oNeL3jQRXA1I4OZM6AR2h9B8yXQQmk",
        });

        setFcm(token);
        return token;
      } catch (error) {
        console.error("Error obtaining token:", error.message);
        throw error;
      }
    } else if (requestedPermission === "denied") {

      return null;
    }
  }
  // // fcm end

  // let num = 0;
  const [num, setNum] = useState(0);
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(getPractitioner(id));
    const body = {
      id: id
    };
    dispatch(getSchedules(body));
  }, [selection, dispatch]);
  useEffect(() => {
    dispatch(setSelection(num));
  }, [num]);
  const handleSide1 = () => {
    // num = 0;
    setNum(0);
    dispatch(setSelection(num));
  };
  const handleSide2 = () => {
    // num = 1;
    setNum(1);
    dispatch(setSelection(num));
  };
  const handleSide3 = (userDetail) => {
    // num = 2;
    setNum(2);
    dispatch(setSelection(num));
  };
  const handleSide4 = () => {
    // num = 3;
    setNum(3);
    dispatch(setSelection(num));
  };
  const handleSide5 = () => {
    // num = 4;
    setNum(4);
    dispatch(setSelection(num));
  };
  const handleSide6 = () => {
    // num = 5;
    setNum(5);
    dispatch(setSelection(num));
  };
  const handleAdd = () => {
    // num = 6;
    setNum(6);
    dispatch(setSelection(num));
  };
  const UpdateProfile = () => {
    // num = 5;
    setNum(5);
    dispatch(setSelection(num));
  };
 


  const handleBookingUpdate = () => {

    setUpdateCount((prev) => prev + 1);
  };


 
  return UserData.user.role == 1 && (
    <div className="w-full relative  bg-m3-sys-light-on-primary overflow-hidden text-gray-800 font-sans flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <PractitionerMainHeader data={practitioner} />
      <section className="self-stretch flex flex-row items-start justify-start max-w-full text-left text-xl text-neutral-8 font-web-secondary-underline-subtitle2-dm-sans mq1050:pl-5 mq1050:pr-5 mq1050:box-border">
        <div className="min-h-[800px] max-h-[1000px] min-w-[300px] max-w-[300px] bg-gray1-300 box-border flex flex-col items-start justify-start py-1 px-[5px]  border-silver-200 mq1050:hidden mq450:gap-[134px]">

          <PractitionerSidebar
            selection={selection}
            handleSide1={handleSide1}
            handleSide2={handleSide2}
            handleSide3={handleSide3}
            handleSide4={handleSide4}
            handleSide5={handleSide5}
            handleSide6={handleSide6}
          />
        </div>
        {practitioner === "isLoading" ? (
          <div className="flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[1000px]">
            <Loader />
          </div>
        ) : (
          practitioner.status === true && (
            <div className="h-full w-full">

              {selection === 0 && (
                <Dashboard 
                practitioner={practitioner}
                  UpdateProfile={UpdateProfile}

                />
              )}
              {selection === 3 && (
                <Services practitioner={practitioner}
                  handleAdd={handleAdd}
                />
              )}
              {selection === 6 && (
                <div className=" flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h[800px]">
                  <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                    <ServiceForm
                      data={
                        practitioner.result[0]
                          ? practitioner.result[0].categories
                          : ""
                      }
                    ></ServiceForm>
                  </div>
                </div>
              )}
              {selection === 7 && (
                <div className=" flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                  <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                    <AddServiceForm
                      data={srvData}
                      categories={
                        practitioner.result[0]
                          ? practitioner.result[0].categories
                          : ""
                      }
                    ></AddServiceForm>
                  </div>
                </div>
              )}
              {selection === 2 && (
                <Appointment
                  practitioner={practitioner}
                />
              )}
              {selection === 1 && (
                <Schedule practitioner={practitioner}  />

              )}
              {selection === 4 && (
                <InboxComponent
                  practitioner={practitioner}

                />
              )}
              {selection === 5 && (
                <Profile
                  practitioner={practitioner}
                  
                />
              )}
              {selection === 8 && (
                <div className=" flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                  <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                    <AddSchedule></AddSchedule>
                  </div>
                </div>
              )}
              {selection === 9 && (
                <div className="flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                  <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                    <EditSchedule></EditSchedule>
                  </div>
                </div>
              )}
            </div>
          )
        )}
      </section>
    </div>
  );
};

export default PractitionerDashboard;
