import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  TextField,
  Stack,
  IconButton,
  Icon
} from "@mui/material";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

import Chip from "@mui/material/Chip";
import { toast } from "react-toastify";

import { updateProfile, resetUpdateProfile } from "../../Actions/Practitioner/UpdateProfileAction";
import { setSelection } from "../../Actions/Practitioner/addServiceAction";
import Loader from "../SubComponent/Loader";
import { getPractitioner } from "../../Actions/Consumer/GetPractitionerAction";
import { deleteImage } from "../../Actions/Practitioner/GalleryImageAction";
import { getAll } from "../../Actions/Catagories/GetCategoriesAction";
import { addCategory } from "../../Actions/Practitioner/ProfileDetailingAction";

const ProfileInfo = ({ data }) => {
  const dispatch = useDispatch();
  const selection = useSelector((state) => state.addService.selection);
  const updateProfileState = useSelector((state) => state.updateProfile.updateProfile);
  const deleteImageState = useSelector((state) => state.deleteImage);
  const practitioner = useSelector((state) => state.practitioner.practitioner);
  const UserData = JSON.parse(localStorage.getItem("user"));
  const [firstName, setFirstName] = useState(data.result[0].firstName);
  const [lastName, setLastName] = useState(data.result[0].lastName);
  const [fullName, setFullName] = useState(`${firstName} ${lastName}`);
  
  const [isCategorySelected, setIsCategorySelected] = useState(false); 
  const [selectedCategory, setSelectedCategory] = useState({});


  const allcategories = useSelector((state) => Array.isArray(state.getCategories.getAll) ? state.getCategories.getAll : []); // Ensure allcategories is an array

  useEffect(() => {
    dispatch(resetUpdateProfile());
    dispatch(getAll());
  }, []);


  const handleChange1 = (event) => {
    const categoryId = event.target.value;
    const category = allcategories.find(cat => cat.id === categoryId);
    setSelectedCategory(category);
    setIsCategorySelected(true);
    console.log(selectedCategory, 'selectedcategory');
  };
  const handleChange = (e) => {
    const value = e.target.value;
    console.log(value, 'value')
    setFullName(value);

    const [newFirstName, ...rest] = value.split(" ");
    setFirstName(newFirstName);
    setLastName(rest.join(" "));
  };

  const [phoneNumber, setPhoneNumber] = useState(data?.result[0]?.phoneNumber);
  const [logoImage, setLogoImage] = useState(
    data?.result[0]?.userImages
      ? data?.result[0].userImages.profileImage
        ? data.result[0].userImages.profileImage
        : ''
      : ''
  );

  const [userImages, setUserImages] = useState(
    data.result[0].userImages?.images ?? []
  );
  const [galleryImages, setGalleryImages] = useState([]);
  const [newGalleryImage, setNewgalleryImage] = useState([]);
  useEffect(() => {

    const imagesFromLocalStorage = loadImagesFromLocalStorage();
    const initialImages = typeof userImages === 'string' && userImages
      ? userImages.split("|")
      : imagesFromLocalStorage;

    setGalleryImages(initialImages);
  }, [userImages]);

  useEffect(() => {

    saveImagesToLocalStorage(galleryImages);
  }, [galleryImages]);
  const [email, setEmail] = useState(data.result[0].email);
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);

  };
  const handleLogoImageChange = (event) => {
    const file = event.target.files[0];


    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

    if (file && file.size <= MAX_FILE_SIZE) {
      const fileType = file.type.split("/")[1];
      if (["png", "jpg", "jpeg"].includes(fileType)) {
        setLogoImage(file);

      } else {
        toast.error("Only PNG, JPG, and JPEG file types are allowed.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Maximum file size allowed is 5MB.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [tab, setTab] = useState(0);

  const handleGalleryImageChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedFiles = [];
    files.forEach((file) => {

      const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

      if (file.size <= MAX_FILE_SIZE) {
        const fileType = file.type.split("/")[1];
        if (["png", "jpg", "jpeg"].includes(fileType)) {
          allowedFiles.push(file);
        } else {
          toast.error("Only PNG, JPG, and JPEG file types are allowed.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("Maximum file size allowed is 5MB.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
    setGalleryImages((prevImages) => {
      console.log('Previous images:', prevImages);
      const updatedImages = [...prevImages, ...allowedFiles];
      console.log('Updated images:', updatedImages);
      return updatedImages;
    });
    setNewgalleryImage((prevNewImages) => [...prevNewImages, ...allowedFiles]);

  };

  const saveImagesToLocalStorage = (images) => {
    const imageUrls = images.map(image =>
      image instanceof File ? URL.createObjectURL(image) : image
    );
    localStorage.setItem('galleryImages', JSON.stringify(imageUrls));
  };

  const loadImagesFromLocalStorage = () => {
    const storedImages = localStorage.getItem('galleryImages');
    return storedImages ? JSON.parse(storedImages) : [];
  };
  const removeGalleryImage = (index, image) => {

    const imageUrl = image instanceof File ? URL.createObjectURL(image) : image;

    dispatch(deleteImage(UserData.user.id, imageUrl))
      .then(() => {
        setGalleryImages((prevImages) => prevImages.filter((_, i) => i !== index));
      })
      .catch((error) => {
        console.error("Failed to delete image:", error);
      });
  };

  const navigate = useNavigate();

  const onTabContainer1Click = () => {
    setTab(1);
  };

  const onTabContainer2Click = () => {
    setTab(2);
  };
  const onTabContainerClick = () => {
    setTab(0);
  };

  const categories = data.result[0].categories
    .map((category) => category.name)
    .join(" , ");
  const userCategories = data.result[0]?.categories || [];
  const categoryIds = userCategories.map((category) => category.id);

  const initialCategoryTags = Object.fromEntries(
    data.result[0].categoryTags.map(({ categoryId, tags }) => [
      categoryId,
      // tags ? tags.split(",") : [], 
      tags ? (tags.includes(',') ? tags.split(',') : tags.split('|')) : [],
    ])
  );

  const [categoryTags, setCategoryTags] = useState(initialCategoryTags);

  const handleKeyDown = (event, categoryId) => {
    if (event.key === "Enter" && event.target.value.trim() !== "") {
      const categoryTagsArray = categoryTags[categoryId] || [];

      const currentTagsCount = categoryTagsArray.length;
      const newTag = event.target.value.trim();

      if (currentTagsCount !== 8) {
        setCategoryTags((prevTags) => ({
          ...prevTags,
          [categoryId]: [...categoryTagsArray, newTag],

        }));

      } else {
        toast.error("You can not add more than 8 tags", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      event.target.value = "";
    }

  };

  const handleDelete = (categoryId, tagToDelete) => () => {
    setCategoryTags((prevTags) => ({
      ...prevTags,
      [categoryId]: prevTags[categoryId].filter((tag) => tag !== tagToDelete),
    }));

  };
  const [bio, setBio] = useState(data.result[0]?.bio ?? '');
  const handleBioChange = (event) => {
    setBio(event.target.value);

  };
  const sessionType = data.result[0].bussinessDetail?.sessionType ?? null;
  const [online, setOnline] = useState(sessionType === 0 || sessionType === 2);
  const [offline, setOffline] = useState(
    sessionType === 1 || sessionType === 2
  );

  const handleOnlineChange = () => {
    if (!online && !offline) {

      setOnline(true);
    } else {

      setOnline(!online);
    }
  };

  const handleOfflineChange = () => {
    if (online && !offline) {

      setOffline(true);
    } else {

      setOffline(!offline);
    }
  };


  useEffect(() => {
    if (!online && !offline) {
      setOnline(true);
    }
  }, [online, offline]);
  const [address, setAddress] = useState(data.result[0].bussinessDetail?.address ?? '');
  const [description, setDescription] = useState(data.result[0].bussinessDetail?.description ?? '');
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleAddressChange = (event) => {
    setAddress(event.target.value);

  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const successMessage = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    toast.success("Profile Updated Successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    dispatch(getPractitioner(user?.user?.id));
  };



  const handleSaveChanges = async () => {

    const user = JSON.parse(localStorage.getItem("user"));
    if (!firstName.trim()) {
      toast.error("Please fill out the  Name field.", { position: "top-right" });
      return;
    }

    if (!bio.trim()) {
      toast.error("Please fill out the Bio field.", { position: "top-right" });
      return;
    }


    if (!description.trim()) {
      toast.error("Please fill out the Description field.", {
        position: "top-right",
      });
      return;
    }
    const hasTags = Object.values(categoryTags).some(tags => tags.length > 0);
    if (!hasTags) {
      toast.error("Please add at least one tag for each category.", { position: "top-right" });
      return;
    }
    if (offline && !address.trim()) {
      toast.error("Please provide an Address .", {
        position: "top-right",
      });
      return;
    }
    if (!logoImage) {
      toast.error("Please upload a profile image.", { position: "top-right" });
      return;
    }

    if (!galleryImages || galleryImages.length === 0) {
      toast.error("Please upload at least one gallery image.", {
        position: "top-right",
      });
      return;
    }
    let session;
    if (online && offline) {
      session = 2;
    } else if (online) {
      session = 0;
    } else if (offline) {
      session = 1;
    }
    try {

      const db = getFirestore();

      console.log("User ID: ", user?.user?.id);

      const userDocRef = doc(db, "users", user?.user?.id);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {

        await updateDoc(userDocRef, {
          img: logoImage,
          name: `${firstName} ${lastName}`,
        });

        console.log("Firestore user updated: ", { name: `${firstName} ${lastName}`, img: logoImage });


      } else {

        await setDoc(userDocRef, {
          id: user?.user?.id,
          img: logoImage,
          name: `${fullName}`,
        });
        console.log("New user document created: ", { name: `${firstName} ${lastName}`, img: logoImage });
        toast.success("Profile created successfully!");
      }

    } catch (error) {
      console.error("Error updating user document: ", error);

    }

    const body = new FormData();
    body.append("UserId", user?.user?.id);
    body.append("FirstName", firstName);
    if (lastName.trim() !== "") {
      body.append("LastName", lastName);
    }

    body.append("FullName", fullName);
    body.append("CountryCode", "+92");
    body.append("PhoneNumber", phoneNumber);
    body.append("Bio", bio);

    const selectedCategoryIds = [];


    categoryIds.forEach((categoryId) => {
      selectedCategoryIds.push(categoryId);
    });

    if (selectedCategory.id) {
      selectedCategoryIds.push(selectedCategory.id);
    }


    selectedCategoryIds.forEach((categoryId) => {
      body.append("selectedCategoryIds", categoryId);
    });
    const selectedCategoryTags = Object.entries(categoryTags).map(
      ([categoryId, tags]) => ({
        categoryId,
        tags: tags.join("|"),
      })
    );

    selectedCategoryTags.forEach((tagObject, index) => {
      body.append(
        `SelectedCategoryTags[${index}].categoryId`,
        tagObject.categoryId
      );
      body.append(`SelectedCategoryTags[${index}].tags`, tagObject.tags);
    });

    body.append("SessionType", session);

    if (offline && address.trim() !== "") {
      body.append("Address", address);
    }

    body.append("TimeZone", timeZone);
    body.append("Description", description);
    body.append("profileImage", logoImage);
    if (newGalleryImage.length > 0) {
      newGalleryImage.forEach((image) => {
        body.append("Images", image);
      });
    }
    dispatch(updateProfile(body));

  };
  useEffect(() => {

    if (updateProfileState && updateProfileState.status === true) {
      const UserData = JSON.parse(localStorage.getItem("user"));

      dispatch(getPractitioner(UserData?.user?.id));
    }
  }, [updateProfileState]);

  useEffect(() => {

    if (practitioner && practitioner.result && practitioner.result.length > 0) {
      const updatedUserData = practitioner.result[0];
      const updatedUser = { ...UserData.user, ...updatedUserData };

      updatedUser.fullName = `${updatedUser.firstName} ${updatedUser.lastName}`;
      updatedUser.profileImage = updatedUserData.userImages?.profileImage || updatedUser?.profileImage;

      console.log("Updated practitioner data: ", updatedUserData);


      localStorage.setItem("user", JSON.stringify({ ...UserData, user: updatedUser }));


      console.log("Updated localStorage: ", JSON.parse(localStorage.getItem("user")));
    } else {
      console.error("Practitioner data is missing or invalid.");
    }


  }, [practitioner, UserData]);

  return (
    <div
      className={`self-stretch flex flex-col items-start justify-start gap-[40px] max-w-full text-left text-13xl text-m3-black font-web-secondary-button-medium mq750:gap-[20px]}`}
    >
      <div className="w-[444px] flex flex-col items-start justify-start gap-[8px] max-w-full">
        <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit inline-block min-w-[100px] mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
          Profile
        </h1>
        <div className="self-stretch relative text-sm leading-[22px]">
          Customize you profile here
        </div>

      </div>

      <div className="self-stretch rounded-tl-none rounded-tr-xl rounded-br-xl rounded-bl-none flex flex-row flex-wrap items-start justify-start py-2 box-border gap-[40px] max-w-full text-xs text-text-secondary font-button-medium mq750:gap-[20px]">
        <div className="flex flex-col items-center justify-start relative gap-[8px]">
          <img
            className="w-[118px] h-[118px] relative rounded-[50%] object-cover"
            alt=""
            src={
              logoImage
                ? typeof logoImage === "string"
                  ? logoImage
                  : URL.createObjectURL(logoImage)
                : "/placeholder_profileImage.png"
            }
          />
          <i className="relative leading-[22px]">
            Please upload square image, size less than 5MB
          </i>
          <input
            className="self-stretch rounded-8xs bg-gray1-200 flex flex-row items-center justify-center pl-10 mq450:flex-wrap mq450:gap-[15px] mq450:pr-5 mq450:box-border"
            type="file"
            accept="image/*"
            src="/solarcameraminimalisticbold.svg"
            onChange={handleLogoImageChange}
          />
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[24px] min-w-[493px] max-w-full mq750:min-w-full">
          <div className="self-stretch flex flex-row items-start justify-start gap-[24px] max-w-full mq750:flex-wrap">
            <div className="flex-1 flex flex-row items-start justify-start min-w-[239px] max-w-full">
              <div className="h-[47px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
                <div className="relative tracking-[0.15px] leading-[12px] inline-block min-w-[56px]">
                  Full Name
                </div>
                <input
                  className="w-full [border:none] [outline:none] bg-[transparent] self-stretch h-6 flex flex-row items-start justify-start font-button-medium text-base text-m3-ref-neutral-neutral20 min-w-[220px]"
                  value={fullName}
                  onChange={handleChange}
                  type="text"
                  maxLength={50}
                />
                <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-m3-ref-neutral-neutral20" />
              </div>
              <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                  Helper text
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-row items-start justify-start min-w-[239px] max-w-full text-neutral-7">
              <div className="h-[47px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
                <div className="relative tracking-[0.15px] leading-[12px] inline-block min-w-[31px]">
                  Email
                </div>
                <input
                  className="w-full [border:none] [outline:none] bg-[transparent] self-stretch h-6 flex flex-row items-start justify-start font-button-medium text-base text-m3-ref-neutral-neutral20 min-w-[280px]"
                  placeholder={email}
                  type="text"
                  disabled
                />
                <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-m3-ref-neutral-neutral20" />
              </div>
              <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border text-text-disabled">
                <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                  Helper text
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[24px] max-w-full mq750:flex-wrap">
            <div className="flex-1 flex flex-row items-start justify-start min-w-[239px] max-w-full">
              <div className="h-[47px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
                <div className="relative tracking-[0.15px] leading-[12px] inline-block min-w-[66px]">
                  Contact No.
                </div>
                <input
                  className="w-full [border:none] [outline:none] bg-[transparent] self-stretch h-6 flex flex-row items-start justify-start font-button-medium text-base text-m3-ref-neutral-neutral20 min-w-[220px]"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}

                  maxLength={15}
                />
                <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-m3-ref-neutral-neutral20" />
              </div>
              <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                  Helper text
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-row items-start justify-start min-w-[239px] max-w-full text-neutral-7">
              {(!categories || (categories !== undefined && categories.length === 0)) ? (

                <FormControl variant="standard" sx={{ minWidth: 250 }}>
                  <InputLabel id="category-select-label"> Service Category</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={selectedCategory.id}
                    onChange={handleChange1}
                    label="select Category"
                    required
                    disabled={isCategorySelected}
                  >
                    {allcategories.length > 0 ? (
                      allcategories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No Categories Available</MenuItem>
                    )}
                  </Select>
                </FormControl>
              ) : (

                <div className="h-[47px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
                  <div className="relative tracking-[0.15px] leading-[12px] inline-block min-w-[103px]">
                    Service Categories
                  </div>
                  <input
                    className="w-full [border:none] [outline:none] bg-[transparent] self-stretch h-6 flex flex-row items-start justify-start font-button-medium text-base text-m3-ref-neutral-neutral20 min-w-[280px]"
                    placeholder={categories}
                    type="text"
                    disabled
                  />
                  <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-m3-ref-neutral-neutral20" />
                  <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border text-text-disabled">
                    <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                      Helper text
                    </div>
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>
      </div>
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-start text-sm text-text-secondary font-button-medium">
        <div className="flex flex-row items-start justify-start">
          <div
            className={`h-[42px] w-[69px] flex flex-col items-center justify-center relative ${tab === 0 ? "text-primary-main" : ""
              }`}
          >
            <div className="!m-[0] absolute top-[0px] left-[0px] flex flex-row items-start justify-start py-[9px] px-4 box-border gap-[8px] w-full h-full">
              <div
                className="relative tracking-[0.4px] leading-[24px] uppercase font-medium inline-block min-w-[37px] cursor-pointer"
                onClick={onTabContainerClick}
              >
                Tags
              </div>
            </div>
            <div className="w-[calc(100%_+_2px)] h-0.5 absolute !m-[0] right-[-2px] bottom-[-2px] left-[0px] box-border border-t-[2px] border-solid border-primary-main" />
          </div>
          <div
            className={`flex flex-row items-start justify-start cursor-pointer ${tab === 1 ? "text-primary-main" : ""
              }`}
            onClick={onTabContainer1Click}
          >
            <div className="flex flex-row items-start justify-start py-[9px] px-4 gap-[8px]">
              <div className="hidden flex-row items-start justify-start">
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0"
                  alt=""
                  src="/lockfilled.svg"
                />
              </div>
              <div className="relative tracking-[0.4px] leading-[24px] uppercase font-medium inline-block min-w-[33px]">
                info
              </div>
            </div>
          </div>
          <div
            className={`flex flex-row items-start justify-start cursor-pointer ${tab === 2 ? "text-primary-main" : ""
              }`}
            onClick={onTabContainer2Click}
          >
            <div className="flex flex-row items-start justify-start py-[9px] px-4 gap-[8px]">
              <div className="hidden flex-row items-start justify-start">
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0"
                  alt=""
                  src="/notificationsfilled.svg"
                />
              </div>
              <div className="relative tracking-[0.4px] leading-[24px] uppercase font-medium inline-block min-w-[62px]">
                Gallery
              </div>
            </div>
          </div>
          <div className="self-stretch hidden flex-col items-center justify-center">
            <div className="w-[59px] flex-1 flex flex-row items-center justify-center py-[9px] px-[15px] box-border gap-[8px]">
              <div className="hidden flex-row items-start justify-start">
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0"
                  alt=""
                  src="/starsharp-1.svg"
                />
              </div>
              <div className="self-stretch flex-1 relative tracking-[0.4px] leading-[24px] uppercase font-medium">
                Tab
              </div>
            </div>
          </div>
        </div>
      </div>

      {tab === 0 && (
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full text-base">
          {userCategories?.length > 0 ? (
            userCategories.map((category) => (
              <div
                key={category.id}
                className="self-stretch relative leading-[24px] font-semibold"
              >
                {category.displayName}:
                <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-2 box-border gap-[16px] max-w-full text-xs text-text-secondary">
                  <div className="self-stretch flex flex-row items-start justify-start max-w-full">
                    <TextField
                      className="[border:none] bg-white h-[57px] flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
                      color="primary"
                      label="Add Tags"
                      helperText="add up to 8 tags"
                      fullWidth={true}
                      variant="standard"
                      onKeyDown={(event) => handleKeyDown(event, category.id)}
                      sx={{ "& .MuiInputBase-root": { height: "57px" } }}
                    />
                    <div className="flex-1 hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border max-w-full">
                      <div className="self-stretch relative leading-[18px] flex items-center whitespace-nowrap max-w-full">
                        You can add up to 8 tags.
                      </div>
                    </div>
                  </div>
                  <div className="items-start justify-start py-0 pl-0 box-border gap-[16px] min-h-[30px] max-w-[200px]">
                    <Stack direction="row" spacing={1}>
                      {categoryTags[category.id] &&
                        categoryTags[category.id].map((tag, index) => (
                          <Chip
                            key={index}
                            label={tag}
                            size="small"
                            onDelete={handleDelete(category.id, tag)}
                          />
                        ))}
                    </Stack>
                  </div>
                  <div className="self-stretch h-[47px] flex flex-col items-start justify-start pt-0 px-0 pb-0 box-border max-w-full text-text-secondary font-components-helper-text">
                    <div className="text-lg font-semibold text-m3-ref-neutral-neutral20-200 mb-2">
                      Bio
                    </div>
                    <TextField
                      className="[border:none] bg-[transparent] h-[57px] flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
                      color="primary"
                      label="I am a"
                      fullWidth={true}
                      variant="standard"
                      value={bio}
                      onChange={handleBioChange}
                      inputProps={{ maxLength: 50 }}
                      sx={{ "& .MuiInputBase-root": { height: "57px" } }}
                    />
                    <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                      <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                        Helper text
                      </div>
                    </div>
                    <p className="text-right w-full mt-1 mb-2">
                      {bio.length}/50
                    </p>
                  </div>
                  <div className="self-stretch h-px flex flex-row items-start justify-start pt-px px-0 pb-0 box-border max-w-full">
                    <div className="h-px w-0 relative overflow-hidden shrink-0 hidden" />
                  </div>
                </div>
              </div>
            ))
          ) : Object.keys(selectedCategory).length > 0 ? (
            <div key={selectedCategory.id} className="self-stretch relative leading-[24px] font-semibold">
              {selectedCategory.displayName}:
              <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-2 box-border gap-[16px] max-w-full text-xs text-text-secondary">
                <div className="self-stretch flex flex-row items-start justify-start max-w-full">
                  <TextField
                    className="[border:none] bg-white h-[57px] flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
                    color="primary"
                    label="Add Tags"
                    helperText="add up to 8 tags"
                    fullWidth={true}
                    variant="standard"
                    onKeyDown={(event) => handleKeyDown(event, selectedCategory.id)} // Use the selected category ID
                    sx={{ "& .MuiInputBase-root": { height: "57px" } }}
                  />
                </div>
                <div className="items-start justify-start py-0 pl-0 box-border gap-[16px] min-h-[30px] max-w-[200px]">
                  <Stack direction="row" spacing={1}>
                    {categoryTags[selectedCategory.id] && categoryTags[selectedCategory.id].map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        size="small"
                        onDelete={handleDelete(selectedCategory.id, tag)}
                      />
                    ))}
                  </Stack>
                </div>
                <div className="self-stretch h-[47px] flex flex-col items-start justify-start pt-0 px-0 pb-0 box-border max-w-full text-text-secondary font-components-helper-text">
                  <div className="text-lg font-semibold text-m3-ref-neutral-neutral20-200 mb-2">
                    Bio
                  </div>
                  <TextField
                    className="[border:none] bg-[transparent] h-[57px] flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
                    color="primary"
                    label="I am a"
                    fullWidth={true}
                    variant="standard"
                    value={bio}
                    onChange={handleBioChange}
                    inputProps={{ maxLength: 50 }}
                    sx={{ "& .MuiInputBase-root": { height: "57px" } }}
                  />
                  <p className="text-right w-full mt-1 mb-2">
                    {bio.length}/50
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}


      {tab === 1 && (
        <div className="self-stretch flex flex-col items-start justify-start gap-[30px] max-w-full text-base max-h-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-base">
            <div className="relative leading-[24px] font-semibold inline-block min-w-[105px]">
              Session Type:
            </div>
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[24px]">
              <div className="flex-1 flex flex-row items-start justify-start gap-[16px] min-w-[204px]">
                <input
                  className="m-0 h-6 w-6 relative rounded overflow-hidden shrink-0 min-h-[24px]"
                  type="checkbox"
                  checked={online}
                  onChange={handleOnlineChange}
                />
                <div className="flex-1 relative leading-[24px]">Online</div>
              </div>
              <div className="flex-1 flex flex-row items-start justify-start gap-[16px] min-w-[204px] mq450:flex-wrap">
                <input
                  className="m-0 h-6 w-6 relative rounded overflow-hidden shrink-0"
                  type="checkbox"
                  checked={offline}
                  onChange={handleOfflineChange}
                />
                <div className="flex-1 relative leading-[24px] inline-block min-w-[45px]">
                  On-site
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch h-[47px] flex flex-row items-start justify-start pt-0 px-0 pb-0 box-border max-w-full text-text-secondary font-components-helper-text">
            <TextField
              className="[border:none] bg-[transparent] h-[57px] flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
              color="primary"
              label="Address"
              fullWidth={true}
              variant="standard"
              value={address}
              onChange={handleAddressChange}
              disabled={!(offline)}
              sx={{ "& .MuiInputBase-root": { height: "57px" } }}
            />
            <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
              <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                Helper text
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start max-w-full text-text-secondary">
            <TextField
              className="flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 max-w-full"
              color="primary"
              rows={5}
              label="Description"
              variant="standard"
              multiline
              value={description}
              inputProps={{ maxLength: 1000 }}
              onChange={handleDescriptionChange}
            />

          </div>
          <p className="text-right w-full mt-1">
            {description.length}/1000
          </p>
        </div>
      )}
      {tab === 2 && (
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
          <div>
            {galleryImages &&
              galleryImages.map((image, index) => (
                <div key={index} className="relative inline-block">
                  {image && (
                    <>
                      <img
                        className="h-[100px] w-[100px] relative"
                        loading="lazy"
                        alt=""
                        src={
                          image instanceof File
                            ? URL.createObjectURL(image)
                            : image
                        }
                      />
                      <IconButton
                        className="absolute top-0 right-0 p-1 bg-white bg-opacity-75"
                        onClick={() => removeGalleryImage(index, image)}
                      >
                        <Icon>close</Icon>
                      </IconButton>
                    </>
                  )}
                </div>
              ))}
          </div>
          <input
            className="self-stretch rounded-xl overflow-hidden flex flex-col items-center justify-center py-[38px] px-[39px] border-[1px] border-dashed border-lightsteelblue"
            type="file"
            accept="image/*"

            onChange={handleGalleryImageChange}

            disabled={galleryImages.length == 3}
          />
        </div>
      )}
      <footer className="self-stretch flex flex-col items-end justify-start">
        <Button
          className="w-[138px] h-10"
          disableElevation={true}
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#fff",
            fontSize: "14",
            background: "#04a3a3",
            borderRadius: "100px",
            "&:hover": { background: "#04a3a3" },
            width: 138,
            height: 40,
          }}
          onClick={handleSaveChanges}

        >
          {updateProfileState?.status &&
            updateProfileState.status == true ?
            successMessage()
            :
            updateProfileState === "isLoading" ? <Loader />
              : "Save Changes"
          }
        </Button>

      </footer>
    </div>
  );
};

export default ProfileInfo;