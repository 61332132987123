
import PractitionerMainHeader from "../components/Layout/PractitionerMainHeader";
import IntroDetails from "../components/Layout/IntroDetails";

const PractitionerProfileDetailing = () => {
  return (
    <div className="relative">
      <div className="absolute inset-0 overflow-hidden">
        <img
          className="absolute top-[-200.1px] right-[-454.2px] w-[1370.2px] h-[1379.9px] object-contain"
          alt=""
          src="/col@2x.png"
        />
      </div>
      <div className="relative z-10 w-full flex flex-col items-start justify-start pt-0 px-0 pb-[769px]  leading-[normal] tracking-[normal] text-left text-29xl text-m3-black font-web-primary-h2-primary-dm-sans mq750:gap-[16px]">
        <PractitionerMainHeader />
        <div className="flex flex-row items-end justify-end py-0 px-16 box-border max-w-full mq750:pl-8 mq750:pr-8 mq750:box-border">
          <div className="flex flex-col items-end justify-end gap-[38px] max-w-full mq675:gap-[19px]">
            <h1 className="mt-5 text-md font-medium relative text-inherit leading-[64px] font-inherit mq450:text-10xl mq450:leading-[38px] mq750:text-19xl mq750:leading-[51px]">
              Tell us more about yourself.
            </h1>
            
          </div>
        </div>
        <div className="ml-10"><IntroDetails /></div>
      </div>
    </div>
  );
};

export default PractitionerProfileDetailing;