import { useState, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
} from "@mui/material";
import { signIn, resetSigninState } from "../Actions/Auth/SigninAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/SubComponent/Loader";
import { useNavigate } from "react-router-dom";
import { confirmUser } from "../Actions/Auth/ConfirmUserAction";

import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "../firebase";
import { toast } from "react-toastify";
import { googleLoginAction, resetGoogleLoginState } from "../Actions/Auth/GoggleLoginAction";


 
const ConsumerSignIn = () => {
  useEffect(() => {
    dispatch(resetSigninState());
  }, []);

  const Practitioner_ROLE = 1;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    userType: 0,
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  const signInState = useSelector(state => state.signin);
  const googleState = useSelector(state=>state.googleLogin_reducer.googleLogin);
  console.log(googleState,'goggle state')
  useEffect(() => {
    const UserData = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    if (UserData && UserData.user && UserData.user.role === Practitioner_ROLE) {
      navigate("/access-denied")
    }

  }, []);
  useEffect(() => {
    if(googleState && googleState !== "isLoading" && googleState.length !== 0 && googleState.status === true ){
      if(googleState.newGoogleSignUp){
        dispatch(resetGoogleLoginState());
        navigateToNext1();
        
      } else{
        dispatch(resetGoogleLoginState());
        navigateToNext(); 
        
      }
    }
  
  }, [googleState, navigate]);
  
  const validateForm = () => {
    let valid = true;
    const newErrors = {
      email: "",
      password: "",
    };

    if (!isValidEmail(formData.email)) {
      newErrors.email = "Invalid Email Format";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleSignIn = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const body = formData;
      dispatch(signIn(body));
    }
  };
  const handleSignUpClick = () => {
    navigate("/consumer-sign-up");
  };
  const handleHomeClick = () => {
    navigate("/consumer-landing-page");
  };
  const handleForgot = () => {
    navigate("/consumer-forgot-password");
  };
  const navigateToNext = () => {
    // localStorage.setItem("user",JSON.stringify(signInState.signIn.result[0]));
    navigate("/search-results-practitioners");
  };
  const navigateToNext1 = () => {
    // localStorage.setItem("user",JSON.stringify(signInState.signIn.result[0]));
    navigate("/consumer-preferences-01");
  };
  const handleConfirm = () => {
    const body = formData;
    dispatch(confirmUser(body));
    navigate("/consumer-sign-up-email-verification");
  };
  const googleLogin = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const password = user.uid + "@"; 
      const body = {
        email: user.email,
        password: password,
        firstName: user.displayName.split(" ")[0],
        lastName: user.displayName.split(" ")[1] || "",
      };

     
       dispatch(googleLoginAction(body));
    
      // if (response?.newGoogleSignUp) {
      //   navigate("/ConsumerPreferences1");
      // } else {
      //   navigate("/search-results-practitioners");
      // }
    } catch (error) {
      console.error("Google Sign-In Error", error);
      toast.error("Google Sign-In Failed", {
        position: "top-center",
      });
    }
  };


  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-row items-center justify-center py-20 pr-20 pl-[163px] box-border gap-[127px] tracking-[normal] leading-[normal] text-left text-29xl text-m3-black font-web-primary-h3-primary-dm-sans mq450:gap-[32px] mq450:pl-5 mq450:box-border mq750:gap-[63px] mq750:pl-[81px] mq750:pr-10 mq750:box-border mq1125:flex-wrap">
      <div className="w-[470px] flex flex-col items-start justify-start pt-[82px] px-0 pb-0 box-border min-w-[470px] max-w-full mq450:pt-[34px] mq450:box-border mq750:min-w-full mq1050:pt-[53px] mq1050:box-border mq1125:flex-1">
        <form className="m-0 self-stretch flex flex-col items-start justify-start gap-[42px] max-w-full mq750:gap-[16px]">
          <h1 className="m-0 relative text-13xl leading-[48px] font-bold font-web-primary-h3-primary-dm-sans text-m3-black text-left mq450:text-lgi mq450:leading-[29px] mq1050:text-7xl mq1050:leading-[38px]">
            Continue your healing journey
          </h1>
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <TextField
              className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
              color="primary"
              label="Email"
              placeholder="user@email.com"
              required={true}
              fullWidth={true}
              variant="standard"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ "& .MuiInputBase-root": { height: "53px" } }}
            />
            <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
              <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-h5-medium text-text-secondary text-left">
                Helper text
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <TextField
              className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full mq450:h-auto"
              color="primary"
              label="Password"
              required={true}
              fullWidth={true}
              variant="standard"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleShowPasswordClick}
                      aria-label="toggle password visibility"
                    >
                      <Icon>
                        {showPassword ? "visibility_off" : "visibility"}
                      </Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ "& .MuiInputBase-root": { height: "53px" } }}
            />
            <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
              <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-h5-medium text-text-secondary text-left">
                Helper text
              </div>
            </div>
          </div>

          <Button
            className="self-stretch h-[60px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq450:pl-5 mq450:pr-5 mq450:box-border"
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "18",
              background: "#f28500",
              border: "#f28500 solid 1px",
              borderRadius: "4px",
              "&:hover": { background: "#f28500" },
              height: 60,
            }}
            onClick={handleSignIn}
          >
            {signInState.signIn === "isLoading" ? (
              <div className="mt-2">
                <Loader />
              </div>
            ) : signInState.signIn.status && signInState.signIn.status == true ? (
              navigateToNext()
            ) : signInState.signIn.errorCode && signInState.signIn.errorCode == "UserNotConfirmed" ? (
              handleConfirm()
            ) :
              (
                "Sign In"
              )}
          </Button>
          <div className="self-stretch rounded overflow-hidden flex flex-row items-start justify-center py-1 px-5 gap-[10px]">
            <div className="bg-hitbox overflow-hidden hidden flex-col items-center justify-center">
              <img
                className="w-3.5 h-3.5 relative overflow-hidden shrink-0 object-cover"
                alt=""
                src="/wrapper-11@2x.png"
              />
            </div>
            <Button
              className="relative min-w-[119px]"
              disableElevation={true}
              variant="text"
              sx={{ borderRadius: "0px 0px 0px 0px" }}
              onClick={handleForgot}
            >
              Forgot Password?
            </Button>
          </div>
          <div className="self-stretch flex flex-row items-start justify-center gap-[11px] mq750:flex-wrap">
            <div className="flex-1 flex flex-col items-start justify-start pt-[9px] px-0 pb-0 box-border min-w-[141px]">
              <div className="self-stretch h-0.5 relative box-border border-t-[2px] border-solid border-whitesmoke-300" />
            </div>
            <div className="relative text-xs leading-[18px] font-web-primary-h3-primary-dm-sans text-neutral-10 text-left inline-block min-w-[14px] mq750:w-full mq750:h-3.5">
              Or
            </div>
            <div className="flex-1 flex flex-col items-start justify-start pt-[9px] px-0 pb-0 box-border min-w-[141px]">
              <div className="self-stretch h-0.5 relative box-border border-t-[2px] border-solid border-whitesmoke-300" />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-center gap-[16px] mq750:flex-wrap">
          <Button
  className="h-10 flex-[0.9022] min-w-[148px] mq450:flex-1"
  startIcon={<img width="24px" height="24px" src="/icons8google-1.svg" />}
  disableElevation={true}
  variant="outlined"
  sx={{
    textTransform: "none",
    color: "#262626",
    fontSize: "14",
    borderColor: "#d9d9d9",
    borderRadius: "50px",
    "&:hover": { borderColor: "#d9d9d9" },
    height: 40,
  }}
  onClick={googleLogin}
>
 
    Sign In with Google

</Button>
            <Button
              className="h-10 flex-1 min-w-[148px]"
              startIcon={<img width="24px" height="24px" src="/facebook.svg" />}
              disableElevation={true}
              variant="outlined"
              sx={{
                textTransform: "none",
                color: "#262626",
                fontSize: "14",
                borderColor: "#d9d9d9",
                borderRadius: "50px",
                "&:hover": { borderColor: "#d9d9d9" },
                height: 40,
              }}
            >
              Sign up with Facebook
            </Button>
          </div>
          <div className="flex flex-row items-start justify-start gap-[2px]">
            <div className="relative text-sm leading-[22px] font-web-primary-h3-primary-dm-sans text-gray-200 text-left mt-2">
              Don’t have an account?
            </div>
            <Button
              variant="text"
              className="text-sm leading-[22px] font-semibold font-web-primary-h3-primary-dm-sans text-royalblue whitespace-nowrap"
              onClick={handleSignUpClick}
              style={{ textTransform: 'lowercase' }}
            >
              Sign Up
            </Button>
          </div>
          <Button
            className="w-[154px] h-[42px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
            startIcon={
              <img width="16px" height="16px" src="/navigate-before.svg" />
            }
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#434343",
              fontSize: "14",
              background: "#fff",
              border: "#d9d9d9 solid 1px",
              borderRadius: "4px",
              "&:hover": { background: "#fff" },
              width: 154,
              height: 42,
            }}
            onClick={handleHomeClick}
          >
            Back to home
          </Button>
        </form>
      </div>
      <div>
      <img src="/Signup.png" width={'664'} height={'994'} />
      </div>
    </div>
  );
};

export default ConsumerSignIn;
