import {CONFIRM_SIGNUP_FAIL, CONFIRM_SIGNUP_PENDING, CONFIRM_SIGNUP_SUCCESS,RESET_CONFIRM_STATE, SET_MESSAGE} from "../Types"
import signUpService from "../../services/Signup-service"
import { toast } from "react-toastify"



export const confirmSignUp = (body) => async (dispatch) => {
    dispatch({
      type: CONFIRM_SIGNUP_PENDING,
      payload: { confirmSignUp: "isLoading" },
    });
    return await signUpService.confirmSignUp(body)
      .then(
        (data) => {
          console.log(data);
          if(data.data.status == true)
          {
            dispatch({
                type: CONFIRM_SIGNUP_SUCCESS,
                payload: {  confirmSignUp: data.data, UserData : data.data.result[0]},
              })
            }
            else
            { dispatch({
                type: CONFIRM_SIGNUP_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: CONFIRM_SIGNUP_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          toast.error(error);
          return Promise.reject();
        }
      )
      .catch((error) => {
        toast.error(error);
        
      });
  };

  export const resetconfirmState = () => ({
    type: RESET_CONFIRM_STATE,
  });